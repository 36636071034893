import React from "react";
import { AppBar, Toolbar, IconButton, Grid } from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import { useLocation, useNavigate } from "react-router-dom";
import NavigationDrawer from "./NavigationDrawer";

const Header = () => {
  const location = useLocation();

  const [openDrawer, setOpenDrawer] = React.useState(false);

  const navigate = useNavigate();

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <React.Fragment>
      <AppBar position="sticky" sx={{ background: "#000", color: "#fff" }}>
        <Toolbar disableGutters>
          <Grid
            container
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
          >
            <Grid item>
              <div>
                <img
                  src="/black-logo-altus.png"
                  alt="Logo Altus"
                  style={{
                    padding: 10,
                    marginLeft: "30px",
                    height: "70px",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate("/")}
                />
              </div>
            </Grid>

            <Grid item>
              {location.pathname !== "/" && (
                <IconButton
                  color="inherit"
                  onClick={toggleDrawer}
                  sx={{ marginRight: "30px" }}
                >
                  <MenuIcon fontSize="large" />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <NavigationDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
    </React.Fragment>
  );
};

export default Header;
