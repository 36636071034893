import React from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getOrganizationByRegisterNumber } from "../../services/OrganizationsServices";
import { Breadcrumbs, Grid, Typography, Container } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import CoverageIndex from "../../components/sustainabilityIndicators/CoverageIndex";

const CoverageIndexDetail = () => {
  const { registerNumber } = useParams();

  const organizationsQuery = useQuery(["organization", registerNumber], () => {
    return getOrganizationByRegisterNumber(registerNumber);
  });

  const {
    data: organization,
    isLoading: loadingOrganization,
    isError: organizationError,
  } = organizationsQuery;

  return (
    <Grid container justifyContent="center" marginTop={5} mb={8}>
      <Grid item display="flex" xs={12} sx={{ marginBottom: 5, marginX: 5 }}>
        <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb">
          <Link underline="hover" className="home-link" to="/">
            Home
          </Link>
          <Link underline="hover" className="home-link" to="/organizations">
            Organizations
          </Link>
          <Link className="home-link" to={`/organizations/${registerNumber}`}>
            {organization?.clientName}
          </Link>
          <Link
            className="home-link"
            to={`/organizations/${registerNumber}/sustainabilityIndicators`}
          >
            Sustainability indicators
          </Link>
          <Typography color="textPrimary">Coverage Index</Typography>
        </Breadcrumbs>
      </Grid>

      <Container maxWidth="lg">
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography
            variant="h4"
            sx={{ marginBottom: 8, fontFamily: "Inter-Medium" }}
          >
            Coverage Index - {organization?.clientName}
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="center"
          rowGap={10}
          sx={{ marginTop: 8 }}
        >
          <Grid item>
            <CoverageIndex coverageIndex={organization?.coverageRate / 100} />
          </Grid>
        </Grid>

        <Grid item sx={{ textAlign: "justify" }} marginTop={10}>
          <Typography
            variant="body1"
            sx={{
              overflowWrap: "break-word",
              textAlign: "justify",
              fontSize: "24px",
            }}
          >
            Represents the proportion of material topics that are managed
            through third-party assessed instruments qualified by Altus. Note:
            in case of managed sub-topics, there are considered for the
            calculation.
          </Typography>
        </Grid>
      </Container>
    </Grid>
  );
};

export default CoverageIndexDetail;
