import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import {
  getOrganizationByRegisterNumber,
  getOrganizationMaterialTopics,
} from "../../services/OrganizationsServices";
import * as Constants from "../../support/Constants";
import {
  DataGrid,
  gridClasses,
  GridFooter,
  GridFooterContainer,
} from "@mui/x-data-grid";
import {
  Breadcrumbs,
  Grid,
  Typography,
  Container,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import { NavigateNext } from "@mui/icons-material";
import Loading from "../Loading";
import NotFound from "../NotFound";

const OrganizationMaterialTopics = () => {
  const { registerNumber } = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const organizationsQuery = useQuery(["organization", registerNumber], () => {
    return getOrganizationByRegisterNumber(registerNumber);
  });

  const {
    data: organization,
    isLoading: loadingOrganization,
    isError: organizationError,
  } = organizationsQuery;

  const materialTopicsQuery = useQuery(
    ["organizationMaterialTopics", registerNumber],
    () => {
      return getOrganizationMaterialTopics(registerNumber);
    }
  );

  const {
    data: materialTopics,
    isLoading: loadingMaterialTopics,
    isError: materialTopicsError,
  } = materialTopicsQuery;

  const materialTopicsColumns = [
    {
      field: "name",
      headerName: "Material Topic",
      flex: 1,
      minWidth: 150,
      headerClassName: "table-header",
      cellClassName: "table-cell",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      renderCell: (params) =>
        Constants.getMaterialTopicStatusName(params.value),
    },
    {
      field: "validityOrDeadlineDate",
      headerName: "Deadline",
      flex: 1,
      minWidth: 150,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      renderCell: (params) => {
        return moment.unix(params.value).format("DD-MM-YYYY");
      },
    },
  ];

  const handleNavigation = (params) => {
    const materialTopic = params.row.code;
    navigate(
      `/organizations/${registerNumber}/materialTopics/${materialTopic}`
    );
  };

  const getRowSpacing = React.useCallback((params) => {
    return {
      top: 5,
      bottom: 5,
    };
  }, []);

  if (loadingOrganization) {
    return <Loading />;
  }

  if (!loadingOrganization && !organization) {
    return <NotFound />;
  }

  const CustomFooter = () => {
    return (
      <GridFooterContainer sx={{ justifyContent: "right" }}>
        <Typography fontSize={isMobile ? "12px" : "18px"} fontWeight="bold">
          Double materiality evaluation:{" "}
          {Constants.getEvaluationResponsibleName(
            organization?.evaluationResponsible
          )}
        </Typography>
        <GridFooter />
      </GridFooterContainer>
    );
  };

  return (
    <Grid container direction="row" justifyContent="center" marginTop={5}>
      <Grid item display="flex" xs={12} sx={{ marginBottom: 5, marginX: 5 }}>
        <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb">
          <Link underline="hover" className="home-link" to="/">
            Home
          </Link>
          <Link underline="hover" className="home-link" to="/organizations">
            Organizations
          </Link>
          <Link className="home-link" to={`/organizations/${registerNumber}`}>
            {organization?.clientName}
          </Link>
          <Typography color="textPrimary">Material Topics</Typography>
        </Breadcrumbs>
      </Grid>

      <Container maxWidth="lg">
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography
            variant="h4"
            sx={{ marginBottom: 8, fontFamily: "Inter-Medium" }}
          >
            Material Topics - {organization?.clientName}
          </Typography>
        </Grid>
        <Grid width="100%" item mt={5}>
          <DataGrid
            onRowClick={(params) => handleNavigation(params)}
            sx={{
              cursor: "pointer",
              [`& .${gridClasses.row}`]: {
                boxShadow:
                  "0px 2px 4px rgba(0, 0, 0, 0.1), 0px -2px 4px rgba(0, 0, 0, 0.1)",

                borderSpacing: 1,
              },
              [`& .${gridClasses.footerContainer}`]: {
                marginTop: 0.5,
                border: "none",
              },

              border: "none",
            }}
            components={
              materialTopics && materialTopics.length > 0
                ? { Footer: CustomFooter }
                : undefined
            }
            rows={materialTopics || []}
            columns={materialTopicsColumns}
            loading={loadingMaterialTopics}
            error={materialTopicsError}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 5, page: 0 },
              },
            }}
            autoHeight
            rowHeight={70}
            getRowId={(row) => row.code}
            getRowSpacing={getRowSpacing}
            disableRowSelectionOnClick
          />
        </Grid>
      </Container>
    </Grid>
  );
};

export default OrganizationMaterialTopics;
