import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { DisclaimerPopup } from "../components/layout/DisclaimerPopup";

const HomePage = () => {
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const isModalShown = localStorage.getItem("blockchain-disclaimer-shown");
    if (!isModalShown) {
      setShowModal(true);
    }
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
    localStorage.setItem("blockchain-disclaimer-shown", "true");
  };
  return (
    <Grid container className="home-page-container">
      <Grid container>
        <Grid
          item
          xs={6}
          onClick={() => navigate("/organizations")}
          className="home-page-option"
        >
          <div
            className="image-overlay"
            style={{
              backgroundImage: "url(organizations-home.jpg)",
            }}
          />
          <Link to="/organizations" className="home-link">
            <Typography className="home-option">Organizations</Typography>
          </Link>
        </Grid>
        <Grid
          item
          xs={6}
          onClick={() => navigate("/services")}
          className="home-page-option"
        >
          <div
            className="image-overlay"
            style={{
              backgroundImage: "url(services-home.jpg)",
            }}
          />
          <Link to="/services" className="home-link">
            <Typography className="home-option">Services</Typography>
          </Link>
        </Grid>
        <Grid
          item
          xs={6}
          onClick={() => navigate("/practices")}
          className="home-page-option"
        >
          <div
            className="image-overlay"
            style={{
              backgroundImage: "url(practices-home.jpg)",
            }}
          />
          <Typography className="home-option">Practices</Typography>
        </Grid>
        <Grid
          item
          xs={6}
          onClick={() => navigate("/reports")}
          className="home-page-option"
        >
          <div
            className="image-overlay"
            style={{
              backgroundImage: "url(reports-home.jpg)",
            }}
          />
          <Typography className="home-option">Reports</Typography>
        </Grid>
      </Grid>

      <DisclaimerPopup open={showModal} handleClose={handleCloseModal} />
    </Grid>
  );
};

export default HomePage;
