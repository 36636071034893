import React, { useState } from "react";
import { Grid, Typography, Card, CardContent } from "@mui/material";
import { useQuery } from "react-query";
import { getOrganizationLogo } from "../../services/OrganizationsServices";
import * as Constants from "../../support/Constants";
import { useNavigate } from "react-router-dom";

const OrganizationItem = ({ organization, hoverEffect, withBorder }) => {
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);

  const { level, registerNumber, clientName, logoFile } = organization;

  const organizationLogoQuery = useQuery(
    ["organizationLogoQuery", logoFile],
    () => {
      return getOrganizationLogo(logoFile);
    }
  );

  const handleHover = () => {
    setHovered(!hovered);
  };

  return (
    <Card
      sx={{
        cursor: hoverEffect && "pointer",
        width: "300px",
        backgroundColor:
          level === Constants.ORGANIZATION_LEVEL_REGISTER ? "#000" : "#fff",
        transition: "transform 0.3s",
        aspectRatio: "1/1",
        border: withBorder ? "1px solid #000;" : "none",
        transform: hoverEffect && hovered && "scale(1.05)",
      }}
      onClick={() => navigate(`/organizations/${registerNumber}`)}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          height: "100%",
        }}
      >
        <Grid
          item
          sx={{
            flexBasis: "70%",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {organizationLogoQuery?.data?.link ? (
            <img
              src={organizationLogoQuery?.data?.link}
              style={{
                width: "220px",
                height: "220px",
                objectFit: "contain",
                padding: 1,
                /*filter:
                  level === Constants.ORGANIZATION_LEVEL_REGISTER
                    ? hoverEffect && hovered
                      ? "invert(0%)"
                      : "invert(100%)"
                    : hoverEffect && hovered
                    ? "invert(100%)"
                    : "invert(0%)",*/
              }}
              alt=""
            />
          ) : (
            <Typography
              variant="h5"
              sx={{
                fontSize: "28px",
                overflowWrap: "break-word",
                color:
                  level === Constants.ORGANIZATION_LEVEL_REGISTER
                    ? "#fff"
                    : "#000",
              }}
            >
              {clientName}
            </Typography>
          )}
        </Grid>
        <Grid item sx={{ flexBasis: "20%" }}>
          <Typography
            variant="subtitle1"
            sx={{
              marginTop: 3,
              fontSize: "18px",
              fontWeight: "bold",
              overflowWrap: "break-word",
              color:
                level === Constants.ORGANIZATION_LEVEL_REGISTER
                  ? "#fff"
                  : "#000",
            }}
          >
            {organization?.level === Constants.ORGANIZATION_LEVEL_REGISTER
              ? `REG ${Constants.formatRegisterNumber(
                  organization?.registerNumber
                )}`
              : `COM ${Constants.formatRegisterNumber(
                  organization?.registerNumber
                )}`}
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OrganizationItem;
