import { Drawer, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const NavigationDrawer = ({ openDrawer, setOpenDrawer }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };
  return (
    <Drawer
      anchor="top"
      open={openDrawer}
      onClose={toggleDrawer}
      PaperProps={{
        sx: {
          height: "40vh",
          backgroundColor: "#000",
          justifyContent: "center",
          alignItems: "center",
        },
      }}
    >
      <Grid container display="flex" sx={{ height: "100%" }}>
        <Grid
          item
          xs={isMobile ? 12 : 12 / 5}
          onClick={() => {
            navigate("/organizations");
            toggleDrawer();
          }}
          className="menu-option"
        >
          <div
            className="image-overlay"
            style={{
              backgroundImage: "url(organizations-home.jpg)",
            }}
          />
          <Typography className="menu-option-text">Organizations</Typography>
        </Grid>

        <Grid
          item
          xs={isMobile ? 12 : 12 / 5}
          onClick={() => {
            navigate("/services");
            toggleDrawer();
          }}
          className="menu-option"
        >
          <div
            className="image-overlay"
            style={{
              backgroundImage: "url(services-home.jpg)",
            }}
          />
          <Typography className="menu-option-text">Services</Typography>
        </Grid>
        <Grid
          item
          xs={isMobile ? 12 : 12 / 5}
          onClick={() => {
            navigate("/practices");
            toggleDrawer();
          }}
          className="menu-option"
        >
          <div
            className="image-overlay"
            style={{
              backgroundImage: "url(practices-home.jpg)",
            }}
          />
          <Typography className="menu-option-text">Practices</Typography>
        </Grid>

        <Grid
          item
          xs={isMobile ? 12 : 12 / 5}
          onClick={() => {
            navigate("/reports");
            toggleDrawer();
          }}
          className="menu-option"
        >
          <div
            className="image-overlay"
            style={{
              backgroundImage: "url(reports-home.jpg)",
            }}
          />
          <Typography className="menu-option-text">Reports</Typography>
        </Grid>
        <Grid
          item
          xs={isMobile ? 12 : 12 / 5}
          onClick={() => {
            navigate("/contracts-info");
            toggleDrawer();
          }}
          className="menu-option"
        >
          <div
            className="image-overlay"
            style={{
              backgroundImage: "url(blockchain.jpg)",
            }}
          />
          <Typography className="menu-option-text">Contracts Data</Typography>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default NavigationDrawer;
