import React from "react";
import { Download, NavigateNext } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import CoveredDimensions from "../../components/organizations/CoveredDimensions";
import OrganizationDetailLinks from "../../components/organizations/OrganizationDetailLinks";

import OrganizationItem from "../../components/organizations/OrganizationItem";
import { getClientByCode } from "../../services/ClientServices";
import {
  getAltusStampFile,
  getOrganizationByRegisterNumber,
  getOrganizationCoveredDimensions,
  getOrganizationLogo,
  getOrganizationMaterialTopic,
  getOrganizationMaterialTopics,
  getOrganizationPublicCommitments,
} from "../../services/OrganizationsServices";
import * as Constants from "../../support/Constants";
import Loading from "../Loading";
import NotFound from "../NotFound";
import { getFiles } from "../../services/FilesServices";
import {
  convertGoogleDriveLink,
  isGoogleDriveLink,
} from "../../utils/parseGoogleDriveImageLink";

const OrganizationDetailPage = () => {
  const { registerNumber } = useParams();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const organizationsQuery = useQuery(["organization", registerNumber], () => {
    return getOrganizationByRegisterNumber(registerNumber);
  });

  const {
    data: organization,
    isLoading: loadingOrganization,
    isError: organizationError,
  } = organizationsQuery;

  const organizationLogoQuery = useQuery(
    ["organizationLogoQuery", organization],
    () => {
      if (organization) {
        return getOrganizationLogo(organization?.logoFile);
      }
    }
  );

  const {
    data: organizationLogo,
    isLoading: loadingOrganizationLogo,
    isError: errorOrganizationLogo,
  } = organizationLogoQuery;

  const altusStampFileQuery = useQuery(
    ["altusStampFileQuery", organization],
    () => {
      if (organization) {
        return getAltusStampFile(organization?.altusStampFile);
      }
    }
  );

  const {
    data: altusStampFile,
    isLoading: loadingStampFile,
    isError: errorStampFile,
  } = altusStampFileQuery;

  const clientQuery = useQuery(["clientQuery", organization], () => {
    if (organization) {
      return getClientByCode(organization?.clientCode);
    }
  });

  const {
    data: client,
    isLoading: loadingClient,
    isError: clientError,
  } = clientQuery;

  const organizationFilesQuery = useQuery(
    ["organizationFiles", organization?.files],
    () => {
      if (organization) {
        return getFiles(organization?.files);
      }
    }
  );

  const {
    data: organizationFiles,
    isLoading: loadingOrganizationFiles,
    isError: organizationFilesError,
  } = organizationFilesQuery;

  const coveredDimensionsQuery = useQuery(
    ["coveredDimensionsQuery", organization],
    () => {
      if (organization) {
        return getOrganizationCoveredDimensions(organization?.registerNumber);
      }
    }
  );

  const {
    data: coveredDimensions,
    isLoading: loadingCoveredDimensions,
    isError: coveredDimensionsError,
  } = coveredDimensionsQuery;

  const publicCommitmentsQuery = useQuery(
    ["organizationPublicCommitments", organization],
    () => {
      if (organization) {
        return getOrganizationPublicCommitments(organization?.registerNumber);
      }
    }
  );
  const {
    data: publicCommitments,
    isLoading: loadingPublicCommitments,
    isError: publicCommitmentsError,
  } = publicCommitmentsQuery;

  const materialTopicsQuery = useQuery(
    ["organizationMaterialTopics", organization],
    () => {
      if (organization) {
        return getOrganizationMaterialTopics(organization?.registerNumber);
      }
    }
  );

  const {
    data: materialTopics,
    isLoading: loadingMaterialTopics,
    isError: materialTopicsError,
  } = materialTopicsQuery;

  if (
    loadingOrganization ||
    loadingClient ||
    loadingCoveredDimensions ||
    loadingOrganizationLogo ||
    loadingOrganizationFiles
  ) {
    return <Loading />;
  }

  if (!loadingOrganization && !organization) {
    return <NotFound />;
  }

  const handleDownload = (file) => {
    fetch(file.link)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file.name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      });
  };

  return (
    <Grid container mt={3} mb={8}>
      <Grid item display="flex" xs={12} sx={{ marginX: 5, marginBottom: 3 }}>
        <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb">
          <Link underline="hover" className="home-link" to="/">
            Home
          </Link>
          <Link underline="hover" className="home-link" to="/organizations">
            Organizations
          </Link>
          <Typography color="textPrimary">{client?.businessName}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid container xs={12} display="flex">
        <Grid container>
          <Grid
            item
            sx={{
              height: "400px",
              width: "100%",
            }}
          >
            <img
              src={
                client?.clientImage
                  ? isGoogleDriveLink(client.clientImage)
                    ? convertGoogleDriveLink(client?.clientImage)
                    : client.clientImage
                  : "/organizations-home.jpg"
              }
              alt={`${client?.businessName} `}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/organizations-home.jpg";
              }}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          </Grid>
          <Grid
            item
            sx={{
              position: "absolute",
              top: "575px",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxWidth: "100%",
              maxHeight: "100%",
            }}
          >
            <OrganizationItem organization={organization} />
          </Grid>
        </Grid>
      </Grid>
      <Container maxWidth="lg">
        <Grid container xs={12} display="flex" justifyContent="center" mt={25}>
          <Grid item>
            <Typography variant="h3" fontFamily="Inter-Medium">
              {client?.businessName}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          gap={1}
          container
          display="flex"
          justifyContent={isMobile ? "center" : "space-between"}
          direction={isMobile ? "column" : "row"}
          mt={5}
        >
          <Grid item>
            <Typography className="register-data-item">
              Level: {Constants.getRegisterLevelName(organization?.level)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className="register-data-item">
              Status:{" "}
              {Constants.getOrganizationStatusName(
                organization?.registerStatus
              )}
            </Typography>
          </Grid>
          <Grid item className="register-data-item">
            <CoveredDimensions coveredDimensions={coveredDimensions} />
          </Grid>
        </Grid>

        <OrganizationDetailLinks
          registerNumber={organization?.registerNumber}
          publicCommitments={publicCommitments}
          materialTopics={materialTopics}
        />

        {altusStampFile && (
          <Grid
            item
            padding={5}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <img
              src={altusStampFile.link}
              alt="Altus Stamp"
              style={{
                height: "200px",
                width: "200px",
                margin: "auto",
              }}
            />
          </Grid>
        )}
        <Grid item sx={{ marginTop: !altusStampFile && 5 }}>
          <Typography
            sx={{
              textAlign: "justify",
              whiteSpace: "pre-line",
            }}
          >
            {client?.organizationProfile}
          </Typography>
        </Grid>

        {organizationFiles && organizationFiles.length > 0 && (
          <Grid
            container
            display="flex"
            direction="row"
            rowGap={2}
            columnGap={2}
            justifyContent={isMobile ? "center" : "left"}
            mt={5}
          >
            {organizationFiles.map((file) => {
              const truncatedName =
                file.name.length > 10
                  ? file.name.substring(0, 10) + "..." + file.extension
                  : file.name;

              return (
                <Grid item>
                  <Tooltip
                    title={<Typography>{file.name}</Typography>}
                    placement="top"
                    arrow
                  >
                    <Button
                      sx={{
                        "&:hover": {
                          backgroundColor: "#fff",
                          color: "#000",
                        },
                        width: 200,
                      }}
                      variant="contained"
                      endIcon={<Download />}
                      onClick={() => handleDownload(file)}
                    >
                      {truncatedName}
                    </Button>
                  </Tooltip>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Container>
      {client?.youtubeLink && (
        <Grid container display="flex" justifyContent="center" mt={5}>
          <iframe
            width="560"
            height="315"
            src={Constants.convertToEmbedUrl(client?.youtubeLink)}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            webkitallowfullscreen
            mozallowfullscreen
          ></iframe>
        </Grid>
      )}
    </Grid>
  );
};

export default OrganizationDetailPage;
