import axios from "axios";
import { url_base_api } from "../utils/config";

export const getClientByCode = async (code) => {
  let client = {};
  const response = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url: `${url_base_api}/clients/${code}`,
  });

  client = {
    code: response.data.clientArray[0].code,
    tradeName: response.data.clientArray[0].tradeName,
    businessName: response.data.clientArray[0].businessName,
    webAddress: response.data.clientArray[0].webAddress,
    physicalAddress: response.data.clientArray[0].physicalAddress,
    organizationProfile: response.data.clientArray[0].organizationProfile,
    state: response.data.clientArray[0].state,
    clientImage: response.data.clientArray[0].imgesArray[0],
    youtubeLink: response.data.clientArray[0].youtubeLink,
  };

  return client;
};
