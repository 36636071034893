import { Grid, Typography } from "@mui/material";
import React from "react";

const NotFound = () => {
  return (
    <Grid
      sx={{
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <Typography variant="h4">Not Found</Typography>
    </Grid>
  );
};

export default NotFound;
