import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import * as Constants from "../../support/Constants";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { Breadcrumbs, Container, Grid, Typography } from "@mui/material";
import moment from "moment";
import { NavigateNext } from "@mui/icons-material";

import { getPractices } from "../../services/PracticesServices";

const PracticesPage = () => {
  const navigate = useNavigate();

  const practicesQuery = useQuery(["practices"], () => {
    return getPractices();
  });

  const { data: practices, isLoading, isError } = practicesQuery;

  const practicesColumns = [
    {
      field: "practiceName",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      headerClassName: "table-header",
      cellClassName: "table-cell",
    },
    {
      field: "clientName",
      headerName: "Organization",
      flex: 1,
      minWidth: 150,
      headerClassName: "table-header",
      cellClassName: "table-cell",
    },
    {
      field: "registerStatus",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      renderCell: (params) => Constants.getOrganizationStatusName(params.value),
    },
    {
      field: "registerValidityDate",
      headerName: "Valid until",
      flex: 1,
      minWidth: 150,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      renderCell: (params) => {
        return moment.unix(params.value).format("DD-MM-YYYY");
      },
    },
    {
      field: "level",
      headerName: "Level",
      flex: 1,
      minWidth: 150,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      renderCell: (params) => Constants.getRegisterLevelName(params.value),
    },
  ];

  const getRowSpacing = React.useCallback((params) => {
    return {
      top: 5,
      bottom: 5,
    };
  }, []);

  const handleNavigation = (params) => {
    const practice = params.row.registerNumber;
    navigate(`/practices/${practice}`, { state: { practiceData: params.row } });
  };

  return (
    <Grid container direction="row" justifyContent="center" marginTop={5}>
      <Grid item display="flex" xs={12} sx={{ marginBottom: 5, marginX: 5 }}>
        <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb">
          <Link underline="hover" className="home-link" to="/">
            Home
          </Link>

          <Typography color="textPrimary">Practices</Typography>
        </Breadcrumbs>
      </Grid>

      <Container maxWidth="lg">
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography
            variant="h4"
            sx={{ marginBottom: 8, fontFamily: "Inter-Medium" }}
          >
            Practices
          </Typography>
        </Grid>
        <Grid item mt={5}>
          <DataGrid
            onRowClick={(params) => handleNavigation(params)}
            sx={{
              cursor: "pointer",
              [`& .${gridClasses.row}`]: {
                boxShadow:
                  "0px 2px 4px rgba(0, 0, 0, 0.1), 0px -2px 4px rgba(0, 0, 0, 0.1)",

                borderSpacing: 1,
              },
              [`& .${gridClasses.footerContainer}`]: {
                marginTop: 0.5,
                border: "none",
              },

              border: "none",
            }}
            rows={practices || []}
            columns={practicesColumns}
            loading={isLoading}
            error={isError}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 5, page: 0 },
              },
            }}
            rowHeight={70}
            getRowId={(row) => row.registerNumber}
            getRowSpacing={getRowSpacing}
            disableRowSelectionOnClick
          />
        </Grid>
      </Container>
    </Grid>
  );
};

export default PracticesPage;
