import axios from "axios";
import { url_base_api } from "../utils/config";

export const getMaterialTopicInstruments = async (materialTopic) => {
  try {
    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${url_base_api}/materialTopics/${materialTopic}/instruments`,
    });
    return response.data.instruments;
  } catch (error) {
    console.log(error);
  }
};

export const getMaterialTopicInstrument = async (materialTopic, instrument) => {
  let instrumentData = {};
  try {
    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${url_base_api}/materialTopics/${materialTopic}/instruments/${instrument}`,
    });

    instrumentData = {
      code: response.data.instrument[0].code,
      name: response.data.instrument[0].name,
      version: response.data.instrument[0].version,
      validityDate: response.data.instrument[0].validityDate,
      issuer: response.data.instrument[0].issuer,
      evaluationType: response.data.instrument[0].evaluationType,
      owner: response.data.instrument[0].owner,
      scope: response.data.instrument[0].scope,
      addOnAltus: response.data.instrument[0].addOnAltus,
      creationDate: response.data.instrument[0].creationDate,
      files: response.data.instrument[0].files,
    };

    return instrumentData;
  } catch (error) {
    console.log(error);
  }
};

export const getMaterialTopicPublicCommitments = async (materialTopic) => {
  try {
    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${url_base_api}/materialTopics/${materialTopic}/publicCommitments`,
    });
    return response.data.publicCommitments;
  } catch (error) {
    console.log(error);
  }
};

export const getMaterialTopicPublicCommitment = async (
  materialTopic,
  publicCommitment
) => {
  let publicCommitmentData = {};
  try {
    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${url_base_api}/materialTopics/${materialTopic}/publicCommitments/${publicCommitment}`,
    });
    publicCommitmentData = {
      code: response.data.publicCommitment[0].code,
      name: response.data.publicCommitment[0].name,
      deadlineDate: response.data.publicCommitment[0].deadlineDate,
      verificationStatus: response.data.publicCommitment[0].verificationStatus,
      commitmentIndex: response.data.publicCommitment[0].commitmentIndex,
      creationDate: response.data.publicCommitment[0].creationDate,
    };
    return publicCommitmentData;
  } catch (error) {
    console.log(error);
  }
};
