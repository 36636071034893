import React from "react";
import { Typography, Box, Grid } from "@mui/material";

const CoveredDimensions = ({ coveredDimensions }) => {
  const { dimensionE, dimensionS, dimensionG } = coveredDimensions;

  return (
    <Grid>
      <Typography className="register-data-item">
        Covered Dimensions: <CircleLetter letter="E" withBorder={dimensionE} />
        <CircleLetter letter="S" withBorder={dimensionS} />
        <CircleLetter letter="G" withBorder={dimensionG} />
      </Typography>
    </Grid>
  );
};

const CircleLetter = ({ letter, withBorder }) => (
  <Box
    component="span"
    sx={{
      display: "inline-block",
      borderRadius: "50%",
      width: "1.5em",
      height: "1.5em",
      textAlign: "center",
      color: "#000",
      marginRight: 1,
      border: withBorder ? "1px solid #000" : "none",
    }}
  >
    {letter}
  </Box>
);

export default CoveredDimensions;
