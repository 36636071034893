import React from "react";
import { Link } from "react-router-dom";

import "./footer.css";
import { Grid } from "@mui/material";

export default function Footer() {
  return (
    <footer className="footer" id="#footer">
      <div className="partners-footer">
        <a
          href="https://www.uniondeexportadores.com/es/"
          target="_blank"
          rel="noopener noreferrer"
          className="partners-item"
          id="ueu"
        >
          <img
            src="/footer-assets/partners/ueu.png"
            alt="Unión de Exportadores del Uruguay"
          />
        </a>
        <a
          href="https://www.latu.org.uy/"
          target="_blank"
          rel="noopener noreferrer"
          className="partners-item"
          id="latu"
        >
          <img src="/footer-assets/partners/latu.svg" alt="LATU" />
        </a>
        <a
          href="https://www.sistemab.org/"
          target="_blank"
          rel="noopener noreferrer"
          className="partners-item"
          id="sistema-b"
        >
          <img src="/footer-assets/partners/sistema-b.png" alt="Sistema B" />
        </a>
        <a
          href="https://deres.org.uy/"
          target="_blank"
          rel="noopener noreferrer"
          className="partners-item"
          id="deres"
        >
          <img src="/footer-assets/partners/deres.png" alt="Deres" />
        </a>
        <a
          href="https://uruguaynatural.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="partners-item"
          id="uruguay"
        >
          <img src="/footer-assets/partners/uruguay.svg" alt="Uruguay 21" />
        </a>
        <a
          href="https://www.lideco.com/lideco/"
          rel="noopener noreferrer"
          target="_blank"
          className="partners-item"
          id="lideco"
        >
          <img src="/footer-assets/partners/lideco.svg" alt="Lideco" />
        </a>
        <a
          href="https://www.pactoglobal.uy/"
          target="_blank"
          rel="noopener noreferrer"
          className="partners-item"
          id="pacto-global"
        >
          <img
            src="/footer-assets/partners/pacto-global.png"
            alt="Pacto Global"
          />
        </a>
        <a
          href="https://www.sincronia.tech/"
          target="_blank"
          rel="noopener noreferrer"
          className="partners-item"
          id="sincronia"
        >
          <img src="/footer-assets/partners/sincronia.svg" alt="Sincronía" />
        </a>
        <a
          href="https://pyxis.tech/"
          target="_blank"
          rel="noopener noreferrer"
          className="partners-item"
          id="pyxis"
        >
          <img src="/footer-assets/partners/pyxis.svg" alt="Pyxis" />
        </a>
        <a
          href="https://fibras.org/"
          target="_blank"
          rel="noopener noreferrer"
          className="partners-item"
          id="fibras"
        >
          <img src="/footer-assets/partners/fibras.svg" alt="Fibras" />
        </a>
      </div>
      <div className="container">
        <div className="logo-altus">
          <img src="/footer-assets/altus/altus-logo-slogan.svg" alt="Altus" />
          <p style={{ width: "50%" }}>
            The data available on this platform is derived from blockchain
            technology, which guarantees transparency, traceability, and
            decentralized data management. It provides a secure and reliable
            environment for accessing and utilizing this information.
          </p>

          <a href="/contracts-info" rel="noopener noreferrer">
            Read More
          </a>
        </div>

        <div className="info-footer">
          <div className="lsqa-info">
            <a
              href="https://lsqa.com.uy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/footer-assets/altus/lsqa-logo.svg" alt="LSQA" />
            </a>
            <ul>
              <li>LSQA Oficina Corporativa</li>
              <li>Av. Italia 6201 | Edificio 'Los Tilos' piso 1</li>
              <li>11500, Montevideo - Uruguay</li>
              <br />
              <li>Phone: (+598) 2600 0165 </li>
              <li>Fax: (+598) 2604 2960</li>
              <li>Email: info@lsqa.com</li>
            </ul>
          </div>

          <div className="altus-info">
            <ul className="social-network">
              <li id="altus-instagram">
                <a
                  href="https://www.instagram.com/altusesg/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/footer-assets/icons/instagram-icon.svg"
                    alt="Instagram"
                  />
                </a>
              </li>
              <li id="altus-facebook">
                <a
                  href="https://www.linkedin.com/showcase/altusesg/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/footer-assets/icons/linkedin-icon.svg"
                    alt="Linkedin"
                  />
                </a>
              </li>
              <li id="altus-twitter">
                <a
                  href="https://twitter.com/Altusesg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/footer-assets/icons/twitter-icon.svg"
                    alt="Twitter"
                  />
                </a>
              </li>
            </ul>
            <ul>
              <li>Phone: (+598) 2600 0165 </li>
              <li>Email: info@altus.uy</li>
            </ul>
          </div>

          <div className="sitemap">
            <h4>Mapa del Sitio</h4>
            <ul>
              <li>
                <Link
                  to="https://www.altus.uy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  What is Altus?
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.altus.uy/process"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Registration Process
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.altus.uy/label"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Application
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.altus.uy/instruments"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Qualified Insruments
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.altus.uy/programs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Programs for Sustainable Development
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.altus.uy/news"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blog and Updates
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.altus.uy/faqs"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  FAQs
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.altus.uy/contact"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
