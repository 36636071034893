import React from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getClientByCode } from "../../services/ClientServices";
import { Breadcrumbs, Container, Grid, Typography } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import moment from "moment";
import * as Constants from "../../support/Constants";
import {
  getService,
  getServiceBackground,
} from "../../services/ServicesServices";

import Loading from "../Loading";
import NotFound from "../NotFound";
import ServiceBackgroundLink from "../../components/services/ServiceBackgroundLink";
import ClientDetail from "../../components/organizations/ClientDetail";
import DimensionIndicator from "../../components/materialTopics/DimensionsIndicator";

const ServiceDetail = () => {
  const { service } = useParams();

  const serviceQuery = useQuery(["service", service], () => {
    return getService(service);
  });

  const {
    data: serviceData,
    isLoading: loadingService,
    isError: serviceError,
  } = serviceQuery;

  const clientQuery = useQuery(["clientQuery", serviceData], () => {
    return getClientByCode(serviceData?.clientCode);
  });

  const {
    data: client,
    isLoading: loadingClient,
    isError: clientError,
  } = clientQuery;

  const backgroundQuery = useQuery(["background", service], () => {
    return getServiceBackground(service);
  });

  const {
    data: backgroundData,
    isLoading: loadingBackground,
    isError: backgroundError,
  } = backgroundQuery;

  if (loadingService || loadingClient) {
    return <Loading />;
  }

  if (!loadingService && !serviceData) {
    return <NotFound />;
  }


  return (
    <Grid container mt={5} mb={10} sx={{ marginX: 5 }}>
      <Grid item display="flex" xs={12} sx={{ marginBottom: 5 }}>
        <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb">
          <Link underline="hover" className="home-link" to="/">
            Home
          </Link>
          <Link underline="hover" className="home-link" to="/services">
            Services
          </Link>
          <Typography color="textPrimary">
            {serviceData?.serviceName}
          </Typography>
        </Breadcrumbs>
      </Grid>

      <Container maxWidth="lg">
        {/*service data*/}
        <Grid
          container
          direction="row"
          display="flex"
          justifyContent="center"
          rowGap={1.5}
        >
          <Grid item justifyContent="center">
            <Typography
              variant="h4"
              align="center"
              sx={{ marginBottom: 8, fontFamily: "Inter-Medium" }}
            >
              {serviceData?.serviceName}
            </Typography>
          </Grid>

          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Level
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {Constants.getRegisterLevelName(serviceData?.level)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Register number
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {Constants.formatRegisterNumber(serviceData?.registerNumber)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Covered Dimensions
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <DimensionIndicator
                dimensionE={serviceData?.dimensionE}
                dimensionS={serviceData?.dimensionS}
                dimensionG={serviceData?.dimensionG}
              />
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Register status
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {Constants.getOrganizationStatusName(
                  serviceData?.registerStatus
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Register date
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {moment
                  .unix(serviceData?.registerEntryDate)
                  .format("DD-MM-YYYY")}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Valid until
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {moment
                  .unix(serviceData?.registerValidityDate)
                  .format("DD-MM-YYYY")}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Description
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {serviceData?.serviceDescription}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Covered SDGs
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {serviceData?.sustainableDevelopmentGoals.length > 0 &&
                  serviceData?.sustainableDevelopmentGoals.join(", ")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {backgroundData.length > 0 && (
          <ServiceBackgroundLink service={service} />
        )}
        {/*client Data*/}
        <ClientDetail client={client} />
      </Container>
    </Grid>
  );
};

export default ServiceDetail;
