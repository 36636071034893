import React from "react";
import { Link, useLocation } from "react-router-dom";

import { NavigateNext } from "@mui/icons-material";
import {
  Breadcrumbs,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import * as Constants from "../support/Constants";

import NotFound from "./NotFound";
import Loading from "./Loading";
import { useQuery } from "react-query";
import { getContractsInfo } from "../services/ContractsServices";

const ContractsInfo = () => {
  const contractsQuery = useQuery(["contracts"], () => {
    return getContractsInfo();
  });

  const { data: contractsData, isLoading, isError } = contractsQuery;

  if (isLoading) {
    return <Loading />;
  }

  if (!isLoading && !contractsData) {
    return <NotFound />;
  }

  return (
    <Grid container mt={5} mb={10} sx={{ marginX: 5 }}>
      <Grid item display="flex" xs={12} sx={{ marginBottom: 5 }}>
        <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb">
          <Link underline="hover" className="home-link" to="/">
            Home
          </Link>
          <Typography color="textPrimary">Contracts Data</Typography>
        </Breadcrumbs>
      </Grid>

      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          display="flex"
          justifyContent="center"
          rowGap={1.5}
        >
          <Grid item justifyContent="center">
            <Typography
              variant="h3"
              align="center"
              sx={{ marginBottom: 5, fontFamily: "Inter-Medium" }}
            >
              Smart Contracts
            </Typography>
          </Grid>

          <Grid item justifyContent="center">
            <Typography
              variant="h6"
              sx={{
                marginBottom: 8,
                whiteSpace: "pre-line",
                textAlign: "justify",
              }}
            >
              {contractsData?.introductionText}
            </Typography>
          </Grid>

          {contractsData?.contracts.map((contract) => (
            <Grid
              xs={12}
              sx={{ marginBottom: 8 }}
              key={contract?.contractLabel}
            >
              <Grid item justifyContent="center">
                <Typography
                  variant="h4"
                  align="center"
                  sx={{ marginBottom: 8, fontFamily: "Inter-Medium" }}
                >
                  {contract?.contractLabel}
                </Typography>
              </Grid>
              <Grid
                container
                padding={2}
                sx={{
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="h6" align="left">
                    Name
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="h6" align="left">
                    {contract?.contractName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                padding={2}
                sx={{
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="h6" align="left">
                    Description
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="h6" align="left">
                    {contract?.contractDescription}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                padding={2}
                sx={{
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="h6" align="left">
                    Contract Address
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    sx={{ overflowWrap: "break-word", overflow: "hidden" }}
                    variant="h6"
                    align="left"
                  >
                    {contract?.contractAddress}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          ))}

          <Grid item justifyContent="center">
            <Typography
              variant="h6"
              sx={{
                marginBottom: 8,
                whiteSpace: "pre-line",
                textAlign: "justify",
              }}
            >
              {contractsData?.endText}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default ContractsInfo;
