import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import {
  getOrganizationByRegisterNumber,
  getOrganizationPublicCommitments,
} from "../../services/OrganizationsServices";
import * as Constants from "../../support/Constants";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { Breadcrumbs, Container, Grid, Typography } from "@mui/material";
import moment from "moment";
import { NavigateNext } from "@mui/icons-material";
import Loading from "../Loading";
import NotFound from "../NotFound";

const OrganizationPublicCommitments = () => {
  const { registerNumber } = useParams();
  const navigate = useNavigate();

  const organizationsQuery = useQuery(["organization", registerNumber], () => {
    return getOrganizationByRegisterNumber(registerNumber);
  });

  const {
    data: organization,
    isLoading: loadingOrganization,
    isError: organizationError,
  } = organizationsQuery;

  const publicCommitmentsQuery = useQuery(
    ["organizationPublicCommitments", registerNumber],
    () => {
      return getOrganizationPublicCommitments(registerNumber);
    }
  );

  const {
    data: publicCommitments,
    isLoading: loadingPublicCommitments,
    isError: publicCommitmentsError,
  } = publicCommitmentsQuery;

  const publicCommitmentsColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      headerClassName: "table-header",
      cellClassName: "table-cell",
    },
    {
      field: "deadlineDate",
      headerName: "Deadline",
      flex: 1,
      minWidth: 150,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      renderCell: (params) => {
        return moment.unix(params.value).format("DD-MM-YYYY");
      },
    },
    {
      field: "verificationStatus",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      renderCell: (params) => Constants.getPublicCommitmentStatus(params.value),
    },
  ];

  const handleNavigation = (params) => {
    const publicCommitment = params.row.code;
    navigate(
      `/organizations/${registerNumber}/publicCommitments/${publicCommitment}`,
      {
        state: {
          publicCommitmentData: params.row,
          organization: organization,
          registerNumber: registerNumber,
        },
      }
    );
  };

  const getRowSpacing = React.useCallback((params) => {
    return {
      top: 5,
      bottom: 5,
    };
  }, []);

  if (loadingOrganization) {
    return <Loading />;
  }

  if (!loadingOrganization && !organization) {
    return <NotFound />;
  }

  return (
    <Grid container direction="row" justifyContent="center" marginTop={5}>
      <Grid item display="flex" xs={12} sx={{ marginBottom: 5, marginX: 5 }}>
        <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb">
          <Link underline="hover" className="home-link" to="/">
            Home
          </Link>
          <Link underline="hover" className="home-link" to="/organizations">
            Organizations
          </Link>
          <Link className="home-link" to={`/organizations/${registerNumber}`}>
            {organization?.clientName}
          </Link>
          <Typography color="textPrimary">Public Commitments</Typography>
        </Breadcrumbs>
      </Grid>

      <Container maxWidth="lg">
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography
            variant="h4"
            sx={{ marginBottom: 8, fontFamily: "Inter-Medium" }}
          >
            Public Commitments - {organization?.clientName}
          </Typography>
        </Grid>
        <Grid item mt={5}>
          <DataGrid
            onRowClick={(params) => handleNavigation(params)}
            sx={{
              cursor: "pointer",
              [`& .${gridClasses.row}`]: {
                boxShadow:
                  "0px 2px 4px rgba(0, 0, 0, 0.1), 0px -2px 4px rgba(0, 0, 0, 0.1)",

                borderSpacing: 1,
              },
              [`& .${gridClasses.footerContainer}`]: {
                marginTop: 0.5,
                border: "none",
              },

              border: "none",
            }}
            rows={publicCommitments || []}
            columns={publicCommitmentsColumns}
            loading={loadingPublicCommitments}
            error={publicCommitments}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 5, page: 0 },
              },
            }}
            autoHeight
            rowHeight={70}
            getRowId={(row) => row.code}
            getRowSpacing={getRowSpacing}
            disableRowSelectionOnClick
          />
        </Grid>
      </Container>
    </Grid>
  );
};

export default OrganizationPublicCommitments;
