import { NavigateNext, ReadMore } from "@mui/icons-material";
import {
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import {
  getOrganizationByRegisterNumber,
  getOrganizationCoveredSDGs,
} from "../../services/OrganizationsServices";
import { sdgData } from "../../utils/sustainableDevelopmetGoalsData";

const SDGDetail = () => {
  const { registerNumber, sdgNumber } = useParams();
  const [odsTargets, setOdsTargets] = useState([]);

  const selectedSDG = sdgData.find((item) => item.id === sdgNumber);

  const organizationsQuery = useQuery(["organization", registerNumber], () => {
    return getOrganizationByRegisterNumber(registerNumber);
  });

  const {
    data: organization,
    isLoading: loadingOrganization,
    isError: organizationError,
  } = organizationsQuery;

  const coveredSDGsQuery = useQuery(["coveredSDGs", registerNumber], () => {
    return getOrganizationCoveredSDGs(registerNumber);
  });

  const {
    data: coveredSDGsData,
    isLoading: loadingCoveredSDGs,
    isError: coveredSDGserror,
  } = coveredSDGsQuery;

  const managedSDGsIds = coveredSDGsData?.odsManaged.map(
    (item) => item?.split(".")[0]
  );

  useEffect(() => {
    let result = [];

    coveredSDGsData?.odsManaged.forEach((target) => {
      result.push({ target, filter: false, managed: true });
    });

    coveredSDGsData?.odsInProcess.forEach((target) => {
      const existingItemIndex = result.findIndex(
        (item) => item.target === target
      );

      if (existingItemIndex !== -1) {
        result[existingItemIndex].filter = false;
      } else {
        result.push({ target, filter: true, managed: false });
      }
    });

    setOdsTargets(result);
  }, [coveredSDGsData]);

  return (
    <Grid container justifyContent="center" marginTop={5}>
      <Grid item display="flex" xs={12} sx={{ marginBottom: 5, marginX: 5 }}>
        <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb">
          <Link className="home-link" to="/">
            Home
          </Link>
          <Link className="home-link" to="/organizations">
            Organizations
          </Link>
          <Link className="home-link" to={`/organizations/${registerNumber}`}>
            {organization?.clientName}
          </Link>
          <Link
            className="home-link"
            to={`/organizations/${registerNumber}/sustainabilityIndicators`}
          >
            Sustainability Indicators
          </Link>
          <Typography color="textPrimary">{selectedSDG?.name}</Typography>
        </Breadcrumbs>
      </Grid>

      <Grid item xs={12} sx={{ textAlign: "center" }}>
        <Typography
          variant="h4"
          sx={{ marginBottom: 8, fontFamily: "Inter-Medium" }}
        >
          {selectedSDG?.name}
        </Typography>
      </Grid>

      <Container maxWidth="lg">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <Grid item>
            <img
              key={selectedSDG?.id}
              src={selectedSDG?.image}
              alt={`SDG ${selectedSDG?.id}`}
              style={{
                width: 350,
                height: 350,
                filter: !managedSDGsIds?.includes(selectedSDG?.id)
                  ? "grayscale(100%)"
                  : "none",
                opacity: !managedSDGsIds?.includes(selectedSDG?.id) ? 0.3 : 1,
              }}
            />
          </Grid>

          <Grid item>
            <Button
              onClick={() => window.open(selectedSDG?.link, "_blank")}
              variant="contained"
              endIcon={<ReadMore />}
              sx={{ backgroundColor: selectedSDG?.bgColor, marginTop: 3 }}
            >
              Read more
            </Button>
          </Grid>
        </Grid>

        <Grid
          xs={12}
          justifyContent="center"
          display="flex"
          item
          sx={{ marginTop: 3 }}
        >
          {odsTargets
            ?.filter((item) => item.target.split(".")[0] === selectedSDG?.id)
            .map((item) => (
              <Tooltip
                title={
                  <Typography>
                    {item.managed ? "Managed" : "In process"}
                  </Typography>
                }
                placement="top"
                arrow
              >
                <Grid
                  key={item}
                  style={{
                    display: "inline-block",
                    width: 75,
                    height: 75,
                    margin: 5,
                    backgroundColor: selectedSDG?.bgColor,
                    filter: item.filter ? "grayscale(100%)" : "none",
                    opacity: item.filter ? 0.3 : 1,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      textAlign: "center",
                      lineHeight: "75px",
                      fontWeight: "bold",
                    }}
                  >
                    {item.target}
                  </Typography>
                </Grid>
              </Tooltip>
            ))}
        </Grid>
      </Container>
    </Grid>
  );
};

export default SDGDetail;
