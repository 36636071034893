import React from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import { NavigateNext } from "@mui/icons-material";
import { Breadcrumbs, Grid, Typography, Container } from "@mui/material";
import {
  getOrganizationByRegisterNumber,
  getOrganizationMaterialTopic,
} from "../../services/OrganizationsServices";

import * as Constants from "../../support/Constants";
import DimensionIndicator from "../../components/materialTopics/DimensionsIndicator";
import moment from "moment";
import MaterialTopicDetailLinks from "../../components/materialTopics/MaterialTopicDetailLinks";

const OrganizationMaterialTopicDetail = () => {
  const { registerNumber, materialTopic } = useParams();

  const organizationsQuery = useQuery(["organization", registerNumber], () => {
    return getOrganizationByRegisterNumber(registerNumber);
  });

  const {
    data: organization,
    isLoading: loadingOrganization,
    isError: organizationError,
  } = organizationsQuery;

  const materialTopicQuery = useQuery(
    ["materialTopic", registerNumber, materialTopic],
    () => {
      return getOrganizationMaterialTopic(registerNumber, materialTopic);
    }
  );

  const {
    data: materialTopicData,
    isLoading: loadingMaterialTopic,
    isError: materialTopicError,
  } = materialTopicQuery;

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      marginTop={5}
      marginBottom={8}
    >
      <Grid item display="flex" xs={12} sx={{ marginBottom: 5, marginX: 5 }}>
        <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb">
          <Link underline="hover" className="home-link" to="/">
            Home
          </Link>
          <Link underline="hover" className="home-link" to="/organizations">
            Organizations
          </Link>
          <Link className="home-link" to={`/organizations/${registerNumber}`}>
            {organization?.clientName}
          </Link>
          <Link
            className="home-link"
            to={`/organizations/${registerNumber}/materialTopics`}
          >
            Material Topics
          </Link>
          <Typography color="textPrimary">{materialTopicData?.name}</Typography>
        </Breadcrumbs>
      </Grid>
      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          display="flex"
          justifyContent="center"
          item
          rowGap={1.5}
        >
          <Grid item justifyContent="center">
            <Typography
              variant="h4"
              align="center"
              sx={{ marginBottom: 8, fontFamily: "Inter-Medium" }}
            >
              {materialTopicData?.name}
            </Typography>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Covered Dimensions
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                <DimensionIndicator
                  dimensionE={materialTopicData?.dimensionE}
                  dimensionS={materialTopicData?.dimensionS}
                  dimensionG={materialTopicData?.dimensionG}
                />
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Sustainable development goals
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {materialTopicData?.sustainableDevelopmentGoals &&
                  materialTopicData?.sustainableDevelopmentGoals.join(", ")}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Status
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {Constants?.getMaterialTopicStatusName(
                  materialTopicData?.status
                )}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Valid until
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {moment
                  .unix(materialTopicData?.validityOrDeadLineDate)
                  .format("DD-MM-YYYY")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <MaterialTopicDetailLinks
          registerNumber={registerNumber}
          materialTopic={materialTopic}
        />
      </Container>
    </Grid>
  );
};

export default OrganizationMaterialTopicDetail;
