import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Inter-Light",
    button: {
      fontFamily: "Inter-Medium",
    },
  },
  palette: {
    primary: {
      main: "#000",

      contrastText: "#fff",
    },
    secondary: {
      main: "#f44336",
    },
  },
});

export default theme;
