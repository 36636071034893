import React, { useState } from "react";
import { Card, CardContent, Grid, Typography } from "@mui/material";

const CoverageIndex = ({ coverageIndex, hoverEffect }) => {
  const [hovered, setHovered] = useState(false);

  const handleHover = () => {
    setHovered(!hovered);
  };

  return (
    <Card
      sx={{
        cursor: hoverEffect && "pointer",
        width: "300px",
        height: "300px",
        backgroundColor: hoverEffect && hovered ? "#fff" : "#000",
        transition: "background-color 0.3s",
        aspectRatio: "1/1",
        border: "1px solid #000",
      }}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          height: "100%",
        }}
      >
        <Grid
          item
          sx={{
            flexBasis: "70%",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontSize: "60px",
              overflowWrap: "break-word",
              color: hoverEffect && hovered ? "#000" : "#fff",
            }}
          >
            {`${coverageIndex}%`}
          </Typography>
        </Grid>

        <Grid item sx={{ flexBasis: "20%" }}>
          <Typography
            variant="subtitle1"
            sx={{
              marginTop: 3,
              fontSize: "18px",
              fontWeight: "bold",
              overflowWrap: "break-word",
              color: hoverEffect && hovered ? "#000" : "#fff",
            }}
          >
            COVERAGE INDEX
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CoverageIndex;
