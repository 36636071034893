import { NavigateNext } from "@mui/icons-material";
import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const OrganizationDetailLinks = ({
  registerNumber,
  publicCommitments,
  materialTopics,
}) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Grid container spacing={1.5} justifyContent="center" mt={5}>
      <Grid item xs={isMobile ? 12 : 6}>
        <Grid
          container
          className="navigation-grid"
          onClick={() =>
            navigate(`/organizations/${registerNumber}/registerData`)
          }
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography
              className="navigation-grid-title"
              sx={{ overflowWrap: "break-word", overflow: "hidden" }}
            >
              REGISTER DATA
            </Typography>
            <NavigateNext sx={{ justifyContent: "flex-end" }} />
          </Stack>
        </Grid>
      </Grid>
      {materialTopics?.length > 0 && (
        <Grid item xs={isMobile ? 12 : 6}>
          <Grid
            container
            className="navigation-grid"
            onClick={() =>
              navigate(`/organizations/${registerNumber}/materialTopics`)
            }
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography className="navigation-grid-title">
                MATERIAL TOPICS
              </Typography>
              <NavigateNext sx={{ justifyContent: "flex-end" }} />
            </Stack>
          </Grid>
        </Grid>
      )}
      <Grid item xs={isMobile ? 12 : 6}>
        <Grid
          container
          className="navigation-grid"
          onClick={() =>
            navigate(
              `/organizations/${registerNumber}/sustainabilityIndicators`
            )
          }
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography className="navigation-grid-title">
              SUSTAINABILITY INDICATORS
            </Typography>
            <NavigateNext sx={{ justifyContent: "flex-end" }} />
          </Stack>
        </Grid>
      </Grid>
      {publicCommitments?.length > 0 && (
        <Grid item xs={isMobile ? 12 : 6}>
          <Grid
            container
            className="navigation-grid"
            onClick={() =>
              navigate(`/organizations/${registerNumber}/publicCommitments`)
            }
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography className="navigation-grid-title">
                SUSTAINABILITY PUBLIC COMMITMENTS
              </Typography>
              <NavigateNext sx={{ justifyContent: "flex-end" }} />
            </Stack>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default OrganizationDetailLinks;
