import React from "react";
import { Link, useLocation } from "react-router-dom";

import { NavigateNext } from "@mui/icons-material";
import { Breadcrumbs, Container, Grid, Typography } from "@mui/material";
import moment from "moment";
import * as Constants from "../../support/Constants";

import NotFound from "../NotFound";

const BackgroundDetail = () => {
  const location = useLocation();

  if (!location.state) {
    return <NotFound />;
  }

  const { backgroundData, serviceData } = location?.state ?? {};

  return (
    <Grid container mt={5} mb={10} sx={{ marginX: 5 }}>
      <Grid item display="flex" xs={12} sx={{ marginBottom: 5 }}>
        <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb">
          <Link underline="hover" className="home-link" to="/">
            Home
          </Link>
          <Link underline="hover" className="home-link" to="/services">
            Services
          </Link>
          <Link
            underline="hover"
            className="home-link"
            to={`/services/${serviceData?.registerNumber}`}
          >
            {serviceData?.serviceName}
          </Link>
          <Link
            underline="hover"
            className="home-link"
            to={`/services/${serviceData?.registerNumber}/background`}
          >
            Background
          </Link>

          <Typography color="textPrimary">
            {backgroundData?.recipient}
          </Typography>
        </Breadcrumbs>
      </Grid>

      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          display="flex"
          justifyContent="center"
          rowGap={1.5}
        >
          <Grid item justifyContent="center">
            <Typography
              variant="h4"
              align="center"
              sx={{ marginBottom: 8, fontFamily: "Inter-Medium" }}
            >
              {backgroundData?.recipient}
            </Typography>
          </Grid>

          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Description
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {backgroundData?.description}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Verificator
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {backgroundData?.verificator}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Level
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {Constants.getAntecedentVerificationStatusName(
                  backgroundData?.verificationLevel
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Verification date
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {moment
                  .unix(backgroundData?.verificationDate)
                  .format("DD-MM-YYYY")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};

export default BackgroundDetail;
