import { Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

const ClientDetail = ({ client }) => {
  return (
    <Grid
      container
      direction="row"
      display="flex"
      justifyContent="center"
      item
      xs={12}
      rowGap={1.5}
      mt={10}
    >
      <Grid item justifyContent="center">
        <Typography
          variant="h4"
          align="center"
          sx={{ marginBottom: 8, fontFamily: "Inter-Medium" }}
        >
          Organization
        </Typography>
      </Grid>
      <Grid
        container
        padding={2}
        sx={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6" align="left">
            Trade name
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6" align="left">
            {client?.tradeName}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        padding={2}
        sx={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6" align="left">
            Business name
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6" align="right">
            {client?.businessName}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        padding={2}
        sx={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6" align="left">
            Web address
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6" align="left">
            <Link
              to={
                client?.webAddress.startsWith("http")
                  ? client?.webAddress
                  : `https://${client?.webAddress}`
              }
              target="_blank"
            >
              {client?.webAddress}
            </Link>
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        padding={2}
        sx={{
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6" align="left">
            Physical address
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h6" align="left">
            {client?.physicalAddress}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ClientDetail;
