import axios from "axios";
import { url_base_api } from "../utils/config";

export const getServices = async () => {
  const response = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url: `${url_base_api}/services`,
  });

  return response.data.servicesList;
};

export const getService = async (registerNumber) => {
  const response = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url: `${url_base_api}/services/${registerNumber}`,
  });

  return response.data.servicesList[0];
};

export const getServiceBackground = async (registerNumber) => {
  const response = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url: `${url_base_api}/services/${registerNumber}/antecedents`,
  });

  return response.data.antecedents;
};
