import React from "react";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
} from "@mui/material";
import * as Constants from "../../support/Constants";
import { Search } from "@mui/icons-material";

export const OrganizationsFilters = ({
  organizationNameFilter,
  setOrganizationNameFilter,
  registerLevelFilter,
  setRegisterLevelFilter,
  registerStatusFilter,
  setRegisterStatusFilter,
  sortFilter,
  setSortFilter,
}) => {
  return (
    <Grid container display="flex" gap={2}>
      <Grid item>
        <TextField
          label="Search by name"
          size="small"
          value={organizationNameFilter}
          onChange={(newValue) =>
            setOrganizationNameFilter(newValue.target.value)
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search fontSize="small" />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item>
        <TextField
          sx={{ width: 150 }}
          id="outlined-basic"
          label="Register status"
          variant="outlined"
          size="small"
          value={registerStatusFilter}
          select={true}
          defaultValue={Constants.ORGANIZATION_STATUS_ANY}
          onChange={(newValue) =>
            setRegisterStatusFilter(newValue.target.value)
          }
          InputLabelProps={{
            shrink: true,
          }}
        >
          <MenuItem
            key={"_status_" + Constants.ORGANIZATION_STATUS_ANY}
            value={Constants.ORGANIZATION_STATUS_ANY}
          >
            All
          </MenuItem>
          <MenuItem
            key={"_status_" + Constants.ORGANIZATION_STATUS_VIGENTE}
            value={Constants.ORGANIZATION_STATUS_VIGENTE}
          >
            Valid
          </MenuItem>
          <MenuItem
            key={"_status_" + Constants.ORGANIZATION_STATUS_VENCIDO}
            value={Constants.ORGANIZATION_STATUS_VENCIDO}
          >
            Expired
          </MenuItem>
          <MenuItem
            key={"_status_" + Constants.ORGANIZATION_STATUS_RETIRADO}
            value={Constants.ORGANIZATION_STATUS_RETIRADO}
          >
            Withdrawn
          </MenuItem>
          <MenuItem
            key={"_status_" + Constants.ORGANIZATION_STATUS_SUSPENDIDO}
            value={Constants.ORGANIZATION_STATUS_SUSPENDIDO}
          >
            Suspended
          </MenuItem>
          <MenuItem
            key={"_status_" + Constants.ORGANIZATION_STATUS_CANCELADO}
            value={Constants.ORGANIZATION_STATUS_CANCELADO}
          >
            Cancelled
          </MenuItem>
          <MenuItem
            key={"_status_" + Constants.ORGANIZATION_STATUS_ANULADO}
            value={Constants.ORGANIZATION_STATUS_ANULADO}
          >
            Voided
          </MenuItem>
        </TextField>
      </Grid>
      <Grid item>
        <TextField
          sx={{ width: 150 }}
          id="outlined-basic"
          label="Register level"
          variant="outlined"
          size="small"
          value={registerLevelFilter}
          select={true}
          defaultValue={Constants.ORGANIZATION_LEVEL_NO_LEVEL}
          onChange={(newValue) => setRegisterLevelFilter(newValue.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        >
          <MenuItem
            key={"_status_" + Constants.ORGANIZATION_LEVEL_NO_LEVEL}
            value={Constants.ORGANIZATION_LEVEL_NO_LEVEL}
          >
            All
          </MenuItem>
          <MenuItem
            key={"_status_" + Constants.ORGANIZATION_LEVEL_REGISTER}
            value={Constants.ORGANIZATION_LEVEL_REGISTER}
          >
            Register
          </MenuItem>
          <MenuItem
            key={"_status_" + Constants.ORGANIZATION_LEVEL_COMMITMENT}
            value={Constants.ORGANIZATION_LEVEL_COMMITMENT}
          >
            Commitment
          </MenuItem>
        </TextField>
      </Grid>
      <Grid item>
        <TextField
          sx={{ width: 180 }}
          id="outlined-basic"
          label="Sort by"
          variant="outlined"
          size="small"
          value={sortFilter}
          select={true}
          defaultValue={sortFilter}
          onChange={(newValue) => setSortFilter(newValue.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
        >
          <MenuItem key="name" value="name">
            Name
          </MenuItem>
          <MenuItem key="number" value="number">
            Register number
          </MenuItem>
          <MenuItem key="index" value="index">
            Trust index
          </MenuItem>
          <MenuItem key="index" value="coverage">
            Coverage index
          </MenuItem>
        </TextField>
      </Grid>
    </Grid>
  );
};
