import axios from "axios";
import { url_base_api } from "../utils/config";

export const getPractices = async () => {
  const response = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url: `${url_base_api}/practices`,
  });

  return response.data.practicesList;
};

export const getPractice = async (registerNumber) => {
  const response = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url: `${url_base_api}/practices/${registerNumber}`,
  });

  return response.data.practicesList[0];
};
