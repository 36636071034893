import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { AppRouter } from "./routers/AppRouter";
import { ThemeProvider } from "@emotion/react";
import theme from "./theme";

const queryClient = new QueryClient();

const AltusApp = () => {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AppRouter />
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default AltusApp;
