import axios from "axios";
import { url_base_api } from "../utils/config";

export const getReports = async () => {
  const response = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url: `${url_base_api}/reports`,
  });

  return response.data.reportsList;
};

export const getReport = async (registerNumber) => {
  let service = {};
  const response = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url: `${url_base_api}/reports/${registerNumber}`,
  });

  return response.data.reportsList[0];
};

export const getReportFile = async (code) => {
  let reportFile = {};
  const fileCode = parseInt(code?.hex);
  if (fileCode === 0) return;
  const response = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url: `${url_base_api}/files/${fileCode}`,
  });

  if (response.data.files.length > 0) {
    reportFile = {
      code: response.data.files[0].code,
      name: response.data.files[0].name,
      extension: response.data.files[0].extension,
      hashCode: response.data.files[0].hashCode,
      link: `https://ipfs.io/ipfs/${response.data.files[0].hashCode}?fileName=${response.data.files[0].name}&download=true`,
    };
  }
  return reportFile;
};

export const getReportVerificationStatement = async (code) => {
  let verificationStatementFile = {};
  const fileCode = parseInt(code?.hex);
  if (fileCode === 0) return;
  const response = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url: `${url_base_api}/files/${fileCode}`,
  });

  if (response.data.files.length > 0) {
    verificationStatementFile = {
      code: response.data.files[0].code,
      name: response.data.files[0].name,
      extension: response.data.files[0].extension,
      hashCode: response.data.files[0].hashCode,
      link: `https://ipfs.io/ipfs/${response.data.files[0].hashCode}?fileName=${response.data.files[0].name}&download=true`,
    };
  }
  return verificationStatementFile;
};
