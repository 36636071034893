import React from "react";
import {
  Button,
  Modal,
  Card,
  CardHeader,
  CardContent,
  Grid,
  CardActions,
  Typography,
  useMediaQuery,
  Stack,
} from "@mui/material";

import { useNavigate } from "react-router-dom";

export const DisclaimerPopup = ({ open, handleClose }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "90%" : "50%",
    bgcolor: "#fff",
    border: "1px solid #000",
    boxShadow: 24,
  };
  return (
    <Modal
      open={open}
      onClose={() => handleClose(true, null, false)}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid sx={style}>
        <Card sx={{ p: 3, boxShadow: "none" }}>
          <CardHeader
            title="Important Notice"
            titleTypographyProps={{ variant: "h5", fontWeight: "bold" }}
          />
          <CardContent>
            <Grid>
              <Typography textAlign="justify">
                The data available on this platform is derived from blockchain
                technology, which guarantees transparency, traceability, and
                decentralized data management. It provides a secure and reliable
                environment for accessing and utilizing this information.
              </Typography>
            </Grid>
          </CardContent>
          <CardActions>
            <Grid container>
              <Stack
                mt={2}
                direction={isMobile ? "column" : "row"}
                spacing={isMobile ? 1 : 2}
              >
                <Button
                  sx={{
                    "&:hover": {
                      backgroundColor: "#fff",
                      color: "#000",
                    },
                  }}
                  size={isMobile ? "small" : "medium"}
                  variant="contained"
                  onClick={() => {
                    handleClose();
                    navigate("/contracts-info");
                  }}
                >
                  Read more
                </Button>
                <Button
                  sx={{
                    "&:hover": {
                      backgroundColor: "#fff",
                      color: "#000",
                    },
                  }}
                  size={isMobile ? "small" : "medium"}
                  variant="contained"
                  onClick={() => handleClose()}
                >
                  Accept and continue
                </Button>
              </Stack>
            </Grid>
          </CardActions>
        </Card>
      </Grid>
    </Modal>
  );
};
