import React, { useState } from "react";
import { Container, Grid, Typography } from "@mui/material";

import * as Constants from "../../support/Constants";

import OrganizationItem from "../../components/organizations/OrganizationItem";
import { OrganizationsFilters } from "../../components/organizations/OrganizationsFilters";
import { useQuery } from "react-query";
import { getOrganizations } from "../../services/OrganizationsServices";

const OrganizationsPage = () => {
  const [organizationNameFilter, setOrganizationNameFilter] = useState("");
  const [registerLevelFilter, setRegisterLevelFilter] = useState(
    Constants.ORGANIZATION_LEVEL_NO_LEVEL
  );
  const [sortFilter, setSortFilter] = useState("name");

  const [registerStatusFilter, setRegisterStatusFilter] = useState(
    Constants.ORGANIZATION_STATUS_ANY
  );

  const organizationsQuery = useQuery(
    [
      "organizations",
      organizationNameFilter,
      registerLevelFilter,
      registerStatusFilter,
      sortFilter,
    ],
    () => {
      return getOrganizations(
        registerStatusFilter,
        organizationNameFilter,
        registerLevelFilter,
        sortFilter
      );
    }
  );

  const { data: organizations, isLoading, isError } = organizationsQuery;

  return (
    <Container maxWidth="lg">
      <Grid container justifyContent="center" alignContent="center">
        <Typography
          variant="h4"
          sx={{
            fontFamily: "Inter-Medium",
            marginTop: 5,
          }}
        >
          REGISTERED ORGANIZATIONS
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignContent="center"
        >
          <Grid item sx={{ mt: 3 }}>
            <OrganizationsFilters
              organizationNameFilter={organizationNameFilter}
              setOrganizationNameFilter={setOrganizationNameFilter}
              registerLevelFilter={registerLevelFilter}
              setRegisterLevelFilter={setRegisterLevelFilter}
              registerStatusFilter={registerStatusFilter}
              setRegisterStatusFilter={setRegisterStatusFilter}
              sortFilter={sortFilter}
              setSortFilter={setSortFilter}
            />
          </Grid>
        </Grid>{" "}
        <Grid
          container
          spacing={3}
          justifyContent="center"
          marginTop={2}
          marginBottom={5}
        >
          {organizations && organizations.length > 0 ? (
            organizations.map((organization) => (
              <Grid item key={organization.registerNumber}>
                <OrganizationItem
                  organization={organization}
                  hoverEffect={true}
                  withBorder={true}
                />
              </Grid>
            ))
          ) : isError ? (
            <Typography variant="h5"> Network error</Typography>
          ) : (
            <Typography variant="h5"> No organizations found</Typography>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default OrganizationsPage;
