const backendUrl = process.env.REACT_APP_BACKEND_URL;

if (!backendUrl) {
  alert(
    "Debe definir la url del backend en REACT_APP_BACKEND_URL dentro del archivo .env"
  );
  throw new Error(`Debe definir REACT_APP_BACKEND_URL en .env`);
}

export const url_base_api = `${backendUrl}`;
