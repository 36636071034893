import { NavigateNext } from "@mui/icons-material";
import { Breadcrumbs, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import * as Constants from "../../support/Constants";
import NotFound from "../NotFound";

const PublicCommitmentDetail = () => {
  const location = useLocation();

  if (!location.state) {
    return <NotFound />;
  }

  const { publicCommitmentData, organization, registerNumber } = location.state;

  return (
    <Grid container mt={5} sx={{ marginX: 5 }}>
      <Grid item display="flex" xs={12} sx={{ marginBottom: 5, marginX: 5 }}>
        <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb">
          <Link underline="hover" className="home-link" to="/">
            Home
          </Link>
          <Link underline="hover" className="home-link" to="/organizations">
            Organizations
          </Link>
          <Link className="home-link" to={`/organizations/${registerNumber}`}>
            {organization?.clientName}
          </Link>
          <Link
            className="home-link"
            to={`/organizations/${registerNumber}/publicCommitments`}
          >
            Sustainability Public Commitments
          </Link>
          <Typography color="textPrimary">
            {publicCommitmentData?.name}
          </Typography>
        </Breadcrumbs>
      </Grid>

      <Container>
        {publicCommitmentData && (
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="center"
            rowGap={1.5}
          >
            <Grid item justifyContent="center">
              <Typography
                variant="h4"
                align="center"
                sx={{ marginBottom: 8, fontFamily: "Inter-Medium" }}
              >
                {publicCommitmentData?.name}
              </Typography>
            </Grid>
            <Grid
              container
              padding={2}
              sx={{
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" align="left">
                  Verification Status
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" align="left">
                  {Constants.getPublicCommitmentStatus(
                    publicCommitmentData?.verificationStatus
                  )}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              padding={2}
              sx={{
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" align="left">
                  Evaluation date
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" align="left">
                  {moment
                    .unix(publicCommitmentData?.creationDate)
                    .format("DD-MM-YYYY")}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              padding={2}
              sx={{
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" align="left">
                  Deadline
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" align="left">
                  {moment
                    .unix(publicCommitmentData?.deadlineDate)
                    .format("DD-MM-YYYY")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Container>
    </Grid>
  );
};

export default PublicCommitmentDetail;
