import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import * as Constants from "../../support/Constants";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { Breadcrumbs, Container, Grid, Typography } from "@mui/material";
import moment from "moment";
import { NavigateNext } from "@mui/icons-material";
import Loading from "../Loading";
import NotFound from "../NotFound";
import {
  getService,
  getServiceBackground,
} from "../../services/ServicesServices";

const ServiceBackground = () => {
  const { service } = useParams();
  const navigate = useNavigate();

  const serviceQuery = useQuery(["service", service], () => {
    return getService(service);
  });

  const {
    data: serviceData,
    isLoading: loadingService,
    isError: serviceError,
  } = serviceQuery;

  const backgroundQuery = useQuery(["background", service], () => {
    return getServiceBackground(service);
  });

  const {
    data: backgroundData,
    isLoading: loadingBackground,
    isError: backgroundError,
  } = backgroundQuery;

  const backgroundColumns = [
    {
      field: "recipient",
      headerName: "Recipient",
      flex: 1,
      minWidth: 150,
      headerClassName: "table-header",
      cellClassName: "table-cell",
    },
    {
      field: "verificationLevel",
      headerName: "Verification Level",
      flex: 1,
      minWidth: 150,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      renderCell: (params) =>
        Constants.getAntecedentVerificationStatusName(params.value),
    },
    {
      field: "verificator",
      headerName: "Verifier",
      flex: 1,
      minWidth: 150,
      headerClassName: "table-header",
      cellClassName: "table-cell",
    },
    {
      field: "verificationDate",
      headerName: "Verification date",
      flex: 1,
      minWidth: 150,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      renderCell: (params) => {
        return moment.unix(params.value).format("DD-MM-YYYY");
      },
    },
  ];

  const handleNavigation = (params) => {
    const backgroundCode = params.row.code;
    navigate(`/services/${service}/background/${backgroundCode}`, {
      state: { backgroundData: params.row, serviceData: serviceData },
    });
  };

  const getRowSpacing = React.useCallback((params) => {
    return {
      top: 5,
      bottom: 5,
    };
  }, []);

  if (loadingBackground) {
    return <Loading />;
  }

  if (!loadingBackground && !backgroundData) {
    return <NotFound />;
  }

  return (
    <Grid container direction="row" justifyContent="center" marginTop={5}>
      <Grid item display="flex" xs={12} sx={{ marginBottom: 5, marginX: 5 }}>
        <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb">
          <Link underline="hover" className="home-link" to="/">
            Home
          </Link>
          <Link underline="hover" className="home-link" to="/services">
            Services
          </Link>
          <Link className="home-link" to={`/services/${service}`}>
            {serviceData?.serviceName}
          </Link>
          <Typography color="textPrimary">Background</Typography>
        </Breadcrumbs>
      </Grid>

      <Container maxWidth="lg">
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography
            variant="h4"
            sx={{ marginBottom: 8, fontFamily: "Inter-Medium" }}
          >
            Background - {serviceData?.serviceName}
          </Typography>
        </Grid>
        <Grid item mt={5}>
          <DataGrid
            onRowClick={(params) => handleNavigation(params)}
            sx={{
              cursor: "pointer",
              [`& .${gridClasses.row}`]: {
                boxShadow:
                  "0px 2px 4px rgba(0, 0, 0, 0.1), 0px -2px 4px rgba(0, 0, 0, 0.1)",

                borderSpacing: 1,
              },
              [`& .${gridClasses.footerContainer}`]: {
                marginTop: 0.5,
                border: "none",
              },

              border: "none",
            }}
            rows={backgroundData || []}
            columns={backgroundColumns}
            loading={loadingBackground}
            error={backgroundError}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 5, page: 0 },
              },
            }}
            autoHeight
            rowHeight={70}
            getRowId={(row) => row.code}
            getRowSpacing={getRowSpacing}
            disableRowSelectionOnClick
          />
        </Grid>
      </Container>
    </Grid>
  );
};

export default ServiceBackground;
