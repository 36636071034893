import React from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getOrganizationByRegisterNumber } from "../../services/OrganizationsServices";
import { Breadcrumbs, Container, Grid, Typography } from "@mui/material";
import TrustIndex from "../../components/sustainabilityIndicators/TrustIndex";
import { NavigateNext } from "@mui/icons-material";

const TrustIndexDetail = () => {
  const { registerNumber } = useParams();

  const organizationsQuery = useQuery(["organization", registerNumber], () => {
    return getOrganizationByRegisterNumber(registerNumber);
  });

  const {
    data: organization,
    isLoading: loadingOrganization,
    isError: organizationError,
  } = organizationsQuery;

  return (
    <Grid container justifyContent="center" marginTop={5} mb={8}>
      <Grid item display="flex" xs={12} sx={{ marginBottom: 5, marginX: 5 }}>
        <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb">
          <Link underline="hover" className="home-link" to="/">
            Home
          </Link>
          <Link underline="hover" className="home-link" to="/organizations">
            Organizations
          </Link>
          <Link className="home-link" to={`/organizations/${registerNumber}`}>
            {organization?.clientName}
          </Link>
          <Link
            className="home-link"
            to={`/organizations/${registerNumber}/sustainabilityIndicators`}
          >
            Sustainability indicators
          </Link>
          <Typography color="textPrimary">Trust Index</Typography>
        </Breadcrumbs>
      </Grid>
      <Container>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography
            variant="h4"
            sx={{ marginBottom: 8, fontFamily: "Inter-Medium" }}
          >
            Trust Index - {organization?.clientName}
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="center"
          rowGap={10}
          sx={{ marginTop: 8 }}
        >
          <Grid item>
            <TrustIndex trustIndex={organization?.confidenceIndex / 100} />
          </Grid>
        </Grid>

        <Grid item sx={{ textAlign: "center" }} marginTop={10}>
          <Typography
            variant="body1"
            sx={{
              overflowWrap: "break-word",
              textAlign: "justify",
              fontSize: "24px",
            }}
          >
            Reflects the organization's compliance with commitments made,
            considering 4 components:
            <br />
            1. Maintenance of third-party assessed instruments (e.g., keeping
            certifications valid)
            <br />
            2. Fulfillment of commitments related to the management of material
            topics that would increase the coverage index (e.g., achieving new
            certifications)
            <br />
            3. Honoring commitments to achieve improvement goals in a timely
            manner, verified by Altus
            <br />
            4. No relevant claims or complaints substantiated
          </Typography>
        </Grid>
      </Container>
    </Grid>
  );
};

export default TrustIndexDetail;
