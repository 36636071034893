import { Download, NavigateNext } from "@mui/icons-material";
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useQuery } from "react-query";
import { Link, useLocation } from "react-router-dom";
import { getClientByCode } from "../../services/ClientServices";
import * as Constants from "../../support/Constants";

import ClientDetail from "../../components/organizations/ClientDetail";
import { getFiles } from "../../services/FilesServices";
import Loading from "../Loading";
import NotFound from "../NotFound";
import DimensionIndicator from "../../components/materialTopics/DimensionsIndicator";

const PracticeDetail = () => {
  const location = useLocation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { practiceData } = location?.state ?? {};

  const clientQuery = useQuery(["clientQuery", practiceData], () => {
    return getClientByCode(practiceData?.clientCode);
  });

  const filesQuery = useQuery(["filesQuery", practiceData], () => {
    return getFiles(practiceData?.files);
  });

  const {
    data: files,
    isLoading: loadingFiles,
    isError: filesError,
  } = filesQuery;

  if (!location.state) {
    return <NotFound />;
  }

  const {
    data: client,
    isLoading: loadingClient,
    isError: clientError,
  } = clientQuery;

  if (loadingClient || loadingFiles) {
    return <Loading />;
  }

  if (!loadingClient && !client) {
    return <NotFound />;
  }

  const handleDownload = (file) => {
    fetch(file.link)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file.name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      });
  };

  return (
    <Grid container mt={5} mb={10} sx={{ marginX: 5 }}>
      <Grid item display="flex" xs={12} sx={{ marginBottom: 5 }}>
        <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb">
          <Link underline="hover" className="home-link" to="/">
            Home
          </Link>
          <Link underline="hover" className="home-link" to="/practices">
            Practices
          </Link>
          <Typography color="textPrimary">
            {practiceData?.practiceName}
          </Typography>
        </Breadcrumbs>
      </Grid>

      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          display="flex"
          justifyContent="center"
          rowGap={1.5}
        >
          <Grid item justifyContent="center">
            <Typography
              variant="h4"
              align="center"
              sx={{ marginBottom: 8, fontFamily: "Inter-Medium" }}
            >
              {practiceData?.practiceName}
            </Typography>
          </Grid>

          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Level
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {Constants.getPracticeLevelName(practiceData?.level)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Register number
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {Constants.formatRegisterNumber(practiceData?.registerNumber)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Covered Dimensions
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <DimensionIndicator
                dimensionE={practiceData?.dimensionE}
                dimensionS={practiceData?.dimensionS}
                dimensionG={practiceData?.dimensionG}
              />
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Register status
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {Constants.getPracticeStatusName(practiceData?.registerStatus)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Register date
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {moment
                  .unix(practiceData?.registerCreationDate)
                  .format("DD-MM-YYYY")}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Valid until
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {moment
                  .unix(practiceData?.registerValidityDate)
                  .format("DD-MM-YYYY")}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Issuer
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {practiceData?.evaluator}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Practice KPI
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {practiceData?.practiceKPI}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Description
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {practiceData?.practiceDescription}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Covered SDGs
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {practiceData?.sustainableDevelopmentGoals.length > 0 &&
                  practiceData?.sustainableDevelopmentGoals.join(", ")}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Files
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                <Stack>
                  {files?.length > 0 ? (
                    files?.map((file, index) => {
                      const truncatedName =
                        file.name.length > 10
                          ? file.name.substring(0, 10) + "..." + file.extension
                          : file.name;

                      return (
                        <Box key={index} sx={{ marginBottom: "8px" }}>
                          <Tooltip
                            title={<Typography>{file.name}</Typography>}
                            placement="top"
                            arrow
                          >
                            <Button
                              sx={{
                                "&:hover": {
                                  backgroundColor: "#fff",
                                  color: "#000",
                                },
                              }}
                              size={isMobile ? "small" : "medium"}
                              variant="contained"
                              endIcon={<Download />}
                              onClick={() => handleDownload(file)}
                            >
                              {truncatedName}
                            </Button>
                          </Tooltip>
                        </Box>
                      );
                    })
                  ) : (
                    <Typography variant="h6" align="left">
                      No attached files{" "}
                    </Typography>
                  )}
                </Stack>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/*client Data*/}
        <ClientDetail client={client} />
      </Container>
    </Grid>
  );
};

export default PracticeDetail;
