import React from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getOrganizationByRegisterNumber } from "../../services/OrganizationsServices";
import { getClientByCode } from "../../services/ClientServices";
import { Breadcrumbs, Grid, Typography, Container } from "@mui/material";
import { Cancel, Check, NavigateNext } from "@mui/icons-material";
import moment from "moment";
import * as Constants from "../../support/Constants";
import Loading from "../Loading";
import NotFound from "../NotFound";
import ClientDetail from "../../components/organizations/ClientDetail";

const OrganizationRegisterData = () => {
  const { registerNumber } = useParams();

  const organizationsQuery = useQuery(["organization", registerNumber], () => {
    return getOrganizationByRegisterNumber(registerNumber);
  });

  const {
    data: organization,
    isLoading: loadingOrganization,
    isError: organizationError,
  } = organizationsQuery;

  const clientQuery = useQuery(["clientQuery", organization], () => {
    return getClientByCode(organization?.clientCode);
  });

  const {
    data: client,
    isLoading: loadingClient,
    isError: clientError,
  } = clientQuery;

  if (loadingOrganization || loadingClient) {
    return <Loading />;
  }

  if (!loadingOrganization && !loadingClient && !organization && !client) {
    return <NotFound />;
  }

  return (
    <Grid container mt={5} mb={8} sx={{ marginX: 5 }}>
      <Grid item display="flex" xs={12} sx={{ marginBottom: 5 }}>
        <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb">
          <Link underline="hover" className="home-link" to="/">
            Home
          </Link>
          <Link underline="hover" className="home-link" to="/organizations">
            Organizations
          </Link>
          <Link
            underline="hover"
            className="home-link"
            to={`/organizations/${registerNumber}`}
          >
            {organization?.clientName}
          </Link>
          <Typography color="textPrimary">Register Data</Typography>
        </Breadcrumbs>
      </Grid>

      <Container maxWidth="lg">
        {/*register data*/}
        <Grid
          container
          direction="row"
          display="flex"
          justifyContent="center"
          rowGap={1.5}
        >
          <Grid item justifyContent="center">
            <Typography
              variant="h4"
              align="center"
              sx={{ marginBottom: 8, fontFamily: "Inter-Medium" }}
            >
              Register Data
            </Typography>
          </Grid>

          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Level
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {Constants.getRegisterLevelName(organization?.level)}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Product labelling licence
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {organization?.useLicenceAltusStamp ? <Check /> : <Cancel />}{" "}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Register number
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {Constants.formatRegisterNumber(organization?.registerNumber)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Register date
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {moment
                  .unix(organization?.registerCreationDate)
                  .format("DD-MM-YYYY")}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Status
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {Constants.getOrganizationStatusName(
                  organization?.registerStatus
                )}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Expiration date
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {moment
                  .unix(organization?.registerValidityDate)
                  .format("DD-MM-YYYY")}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/*client Data*/}
        <ClientDetail client={client} />
      </Container>
    </Grid>
  );
};

export default OrganizationRegisterData;
