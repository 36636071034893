import axios from "axios";
import { url_base_api } from "../utils/config";

export const getContractsInfo = async () => {
  const response = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url: `${url_base_api}/contracts`,
  });

  return response.data;
};
