import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getOrganizationByRegisterNumber } from "../../services/OrganizationsServices";
import { Breadcrumbs, Grid, Typography, Container } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import CoverageIndex from "../../components/sustainabilityIndicators/CoverageIndex";
import CoveredSDGs from "../../components/sustainabilityIndicators/CoveredSDGs";
import TrustIndex from "../../components/sustainabilityIndicators/TrustIndex";

const SustainabilityIndicators = () => {
  const { registerNumber } = useParams();
  const navigate = useNavigate();

  const organizationsQuery = useQuery(["organization", registerNumber], () => {
    return getOrganizationByRegisterNumber(registerNumber);
  });

  const {
    data: organization,
    isLoading: loadingOrganization,
    isError: organizationError,
  } = organizationsQuery;

  return (
    <Grid container justifyContent="center" marginTop={5} mb={8}>
      <Grid item display="flex" xs={12} sx={{ marginBottom: 5, marginX: 5 }}>
        <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb">
          <Link underline="hover" className="home-link" to="/">
            Home
          </Link>
          <Link underline="hover" className="home-link" to="/organizations">
            Organizations
          </Link>
          <Link className="home-link" to={`/organizations/${registerNumber}`}>
            {organization?.clientName}
          </Link>
          <Typography color="textPrimary">Sustainability indicators</Typography>
        </Breadcrumbs>
      </Grid>

      <Container maxWidth="lg">
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography
            variant="h4"
            sx={{ marginBottom: 8, fontFamily: "Inter-Medium" }}
          >
            Sustainability Indicators - {organization?.clientName}
          </Typography>
        </Grid>
        <Grid
          container
          justifyContent="center"
          rowGap={
            organization?.coverageIndex !== 0 &&
            organization?.confidenceIndex !== 0
              ? 10
              : 0
          }
          columnGap={
            organization?.coverageIndex !== 0 &&
            organization?.confidenceIndex !== 0
              ? 10
              : 0
          }
          sx={{ marginTop: 10 }}
        >
          {organization?.coverageRate !== 0 && (
            <Grid
              item
              onClick={() =>
                navigate(
                  `/organizations/${registerNumber}/sustainabilityIndicators/coverageIndex`
                )
              }
            >
              <CoverageIndex
                coverageIndex={organization?.coverageRate / 100}
                hoverEffect={true}
              />
            </Grid>
          )}
          {organization?.confidenceIndex !== 0 && (
            <Grid
              item
              onClick={() =>
                navigate(
                  `/organizations/${registerNumber}/sustainabilityIndicators/trustIndex`
                )
              }
            >
              <TrustIndex
                trustIndex={organization?.confidenceIndex / 100}
                hoverEffect={true}
              />
            </Grid>
          )}
        </Grid>

        <CoveredSDGs registerNumber={registerNumber} />
      </Container>
    </Grid>
  );
};

export default SustainabilityIndicators;
