import axios from "axios";
import { url_base_api } from "../utils/config";

export const getOrganizations = async (status, name, level, sortFilter) => {
  const response = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url: `${url_base_api}/organizations?status=${status}&name=${name}&level=${level}&sort=${sortFilter}&ord=asc`,
  });
  return response.data.orgList;
};

export const getOrganizationByRegisterNumber = async (registerNumber) => {
  const response = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url: `${url_base_api}/organizations/${registerNumber}`,
  });
  return response.data.orgList[0];
};

export const getOrganizationLogo = async (logoFile) => {
  let organizationLogo = {};
  try {
    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${url_base_api}/files/${logoFile}`,
    });

    organizationLogo = {
      code: response.data.files[0].code,
      name: response.data.files[0].name,
      link: `https://ipfs.io/ipfs/${response.data.files[0].hashCode}?fileName=${response.data.files[0].name}`,
    };
    return organizationLogo;
  } catch (error) {
    console.log(error);
  }
};

export const getAltusStampFile = async (file) => {
  let altusStampFile = {};
  try {
    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${url_base_api}/files/${file}`,
    });

    altusStampFile = {
      code: response.data.files[0].code,
      name: response.data.files[0].name,
      link: `https://ipfs.io/ipfs/${response.data.files[0].hashCode}?fileName=${response.data.files[0].name}`,
    };
    return altusStampFile;
  } catch (error) {
    console.log(error);
  }
};

export const getOrganizationCoveredDimensions = async (registerNumber) => {
  let coveredDimensions = {
    dimensionE: false,
    dimensionS: false,
    dimensionG: false,
  };
  try {
    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${url_base_api}/organizations/${registerNumber}/materialTopics`,
    });
    const materialTopics = response.data.materialTopics;
    if (materialTopics && materialTopics.length > 0) {
      for (const topic of materialTopics) {
        if (topic.dimensionE) {
          coveredDimensions.dimensionE = true;
        }
        if (topic.dimensionS) {
          coveredDimensions.dimensionS = true;
        }
        if (topic.dimensionG) {
          coveredDimensions.dimensionG = true;
        }
      }
    }
    return coveredDimensions;
  } catch (error) {
    console.log(error);
  }
};

export const getOrganizationMaterialTopics = async (registerNumber) => {
  try {
    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${url_base_api}/organizations/${registerNumber}/materialTopics`,
    });
    return response.data.materialTopics;
  } catch (error) {
    console.log(error);
  }
};

export const getOrganizationMaterialTopic = async (
  registerNumber,
  materialTopic
) => {
  try {
    let materialTopicData = {};
    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${url_base_api}/organizations/${registerNumber}/materialTopics/${materialTopic}`,
    });

    materialTopicData = {
      code: response.data.materialTopic[0].code,
      name: response.data.materialTopic[0].name,
      status: response.data.materialTopic[0].status,
      dimensionE: response.data.materialTopic[0].dimensionE,
      dimensionS: response.data.materialTopic[0].dimensionS,
      dimensionG: response.data.materialTopic[0].dimensionG,
      sustainableDevelopmentGoals:
        response.data.materialTopic[0].sustainableDevelopmentGoals,
      validityOrDeadLineDate:
        response.data.materialTopic[0].validityOrDeadlineDate,
    };
    return materialTopicData;
  } catch (error) {
    console.log(error);
  }
};

export const getOrganizationCoveredSDGs = async (registerNumber) => {
  const response = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url: `${url_base_api}/organizations/${registerNumber}/ods`,
  });

  return response.data;
};

export const getOrganizationPublicCommitments = async (registerNumber) => {
  const response = await axios({
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    url: `${url_base_api}/organizations/${registerNumber}/publicCommitments`,
  });

  return response.data.publicCommitmentsList;
};
