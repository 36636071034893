import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import {
  getOrganizationByRegisterNumber,
  getOrganizationMaterialTopic,
} from "../../services/OrganizationsServices";
import * as Constants from "../../support/Constants";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import { Breadcrumbs, Container, Grid, Typography } from "@mui/material";
import moment from "moment";
import { NavigateNext } from "@mui/icons-material";
import { getMaterialTopicPublicCommitments } from "../../services/MaterialTopicServices";

const MaterialTopicPublicCommitments = () => {
  const { registerNumber, materialTopic } = useParams();
  const navigate = useNavigate();

  const organizationsQuery = useQuery(["organization", registerNumber], () => {
    return getOrganizationByRegisterNumber(registerNumber);
  });

  const {
    data: organization,
    isLoading: loadingOrganization,
    isError: organizationError,
  } = organizationsQuery;

  const materialTopicQuery = useQuery(
    ["materialTopic", registerNumber, materialTopic],
    () => {
      return getOrganizationMaterialTopic(registerNumber, materialTopic);
    }
  );

  const {
    data: materialTopicData,
    isLoading: loadingMaterialTopic,
    isError: materialTopicsError,
  } = materialTopicQuery;

  const materialTopicPublicCommitmentsQuery = useQuery(
    ["materialTopicCommitments", materialTopic],
    () => {
      return getMaterialTopicPublicCommitments(materialTopic);
    }
  );

  const {
    data: publicCommitments,
    isLoading: loadingPublicCommitments,
    isError: publicCommitmentsError,
  } = materialTopicPublicCommitmentsQuery;

  const publicCommitmentsColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 150,
      headerClassName: "table-header",
      cellClassName: "table-cell",
    },
    {
      field: "deadlineDate",
      headerName: "Deadline",
      flex: 1,
      minWidth: 150,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      renderCell: (params) => {
        return moment.unix(params.value).format("DD-MM-YYYY");
      },
    },
    {
      field: "verificationStatus",
      headerName: "Status",
      flex: 1,
      minWidth: 150,
      headerClassName: "table-header",
      cellClassName: "table-cell",
      renderCell: (params) => Constants.getPublicCommitmentStatus(params.value),
    },
  ];

  const handleNavigation = (params) => {
    const publicCommitment = params.row.code;
    navigate(
      `/organizations/${registerNumber}/materialTopics/${materialTopic}/publicCommitments/${publicCommitment}`
    );
  };

  const getRowSpacing = React.useCallback((params) => {
    return {
      top: 5,
      bottom: 5,
    };
  }, []);

  return (
    <Grid container direction="row" justifyContent="center" mt={5} mb={8}>
      <Grid item display="flex" xs={12} sx={{ marginBottom: 5, marginX: 5 }}>
        <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb">
          <Link underline="hover" className="home-link" to="/">
            Home
          </Link>
          <Link underline="hover" className="home-link" to="/organizations">
            Organizations
          </Link>
          <Link className="home-link" to={`/organizations/${registerNumber}`}>
            {organization?.clientName}
          </Link>
          <Link
            className="home-link"
            to={`/organizations/${registerNumber}/materialTopics`}
          >
            Material Topics
          </Link>
          <Link
            className="home-link"
            to={`/organizations/${registerNumber}/materialTopics/${materialTopic}`}
          >
            {materialTopicData?.name}
          </Link>
          <Typography color="textPrimary">
            Sustainability Public Commitments
          </Typography>
        </Breadcrumbs>
      </Grid>

      <Container maxWidth="lg">
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography
            variant="h4"
            align="center"
            sx={{ marginBottom: 8, fontFamily: "Inter-Medium" }}
          >
            Sustainability Public Commitments - {materialTopicData?.name}
          </Typography>
        </Grid>
        <Grid item mt={5} minHeight={300}>
          <DataGrid
            onRowClick={(params) => handleNavigation(params)}
            sx={{
              cursor: "pointer",
              [`& .${gridClasses.row}`]: {
                boxShadow:
                  "0px 2px 4px rgba(0, 0, 0, 0.1), 0px -2px 4px rgba(0, 0, 0, 0.1)",

                borderSpacing: 1,
              },
              [`& .${gridClasses.footerContainer}`]: {
                marginTop: 0.5,
                border: "none",
              },

              border: "none",
            }}
            rows={publicCommitments || []}
            columns={publicCommitmentsColumns}
            loading={loadingPublicCommitments}
            error={publicCommitmentsError}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 5, page: 0 },
              },
            }}
            autoHeight
            getRowId={(row) => row.code}
            getRowSpacing={getRowSpacing}
            disableRowSelectionOnClick
          />
        </Grid>
      </Container>
    </Grid>
  );
};

export default MaterialTopicPublicCommitments;
