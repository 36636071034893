import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { getClientByCode } from "../../services/ClientServices";
import {
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Download, NavigateNext } from "@mui/icons-material";
import moment from "moment";
import * as Constants from "../../support/Constants";

import Loading from "../Loading";
import NotFound from "../NotFound";

import { getReportFile } from "../../services/ReportsServices";
import ClientDetail from "../../components/organizations/ClientDetail";

const ReportDetail = () => {
  const location = useLocation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { reportData } = location?.state ?? {};

  const clientQuery = useQuery(["clientQuery", reportData], () => {
    return getClientByCode(reportData?.clientCode);
  });

  const {
    data: client,
    isLoading: loadingClient,
    isError: clientError,
  } = clientQuery;

  const reportFileQuery = useQuery(["reportFile", reportData], () => {
    return getReportFile(reportData?.report);
  });

  const {
    data: reportFile,
    isLoading: loadingReportFile,
    isError: reportFileError,
  } = reportFileQuery;

  const verificationStatementFileQuery = useQuery(
    ["verificationStatementFile", reportData],
    () => {
      return getReportFile(reportData?.verificationStatement);
    }
  );

  const {
    data: verificationStatementFile,
    isLoading: loadingVerificationStatement,
    isError: verificationStatementError,
  } = verificationStatementFileQuery;

  if (!location.state) {
    return <NotFound />;
  }

  if (loadingClient || loadingReportFile || loadingVerificationStatement) {
    return <Loading />;
  }

  if (!loadingClient && !client) {
    return <NotFound />;
  }

  const handleDownload = (file) => {
    fetch(file.link)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file.name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      });
  };


  return (
    <Grid container mt={5} mb={10} sx={{ marginX: 5 }}>
      <Grid item display="flex" xs={12} sx={{ marginBottom: 5 }}>
        <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb">
          <Link underline="hover" className="home-link" to="/">
            Home
          </Link>
          <Link underline="hover" className="home-link" to="/reports">
            Reports
          </Link>
          <Typography color="textPrimary">{reportData?.reportName}</Typography>
        </Breadcrumbs>
      </Grid>

      <Container maxWidth="lg">
        <Grid
          container
          direction="row"
          display="flex"
          justifyContent="center"
          rowGap={1.5}
        >
          <Grid item justifyContent="center">
            <Typography
              variant="h4"
              align="center"
              sx={{ marginBottom: 8, fontFamily: "Inter-Medium" }}
            >
              {reportData?.reportName}
            </Typography>
          </Grid>

          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Register number
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {Constants.formatRegisterNumber(reportData?.registerNumber)}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Register date
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {moment
                  .unix(reportData?.registerEntryDate)
                  .format("DD-MM-YYYY")}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Report Standard
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {reportData?.reportStandard}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Publish date
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {moment.unix(reportData?.publishDate).format("DD-MM-YYYY")}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Reporting period
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {reportData?.period}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Verifier
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {reportData?.verificatory}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            padding={2}
            sx={{
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                Verification Standard
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" align="left">
                {reportData?.standardAssuranceUsed}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/*report files*/}

        {(reportFile || verificationStatementFile) && (
          <Grid container mt={5}>
            <Stack direction={isMobile ? "column" : "row"} spacing={2}>
              {reportFile && (
                <Button
                  sx={{
                    "&:hover": {
                      backgroundColor: "#fff",
                      color: "#000",
                    },
                    width: 150,
                  }}
                  size={isMobile ? "small" : "medium"}
                  variant="contained"
                  endIcon={<Download />}
                  onClick={() => handleDownload(reportFile)}
                >
                  Report
                </Button>
              )}
              {verificationStatementFile && (
                <Button
                  sx={{
                    "&:hover": {
                      backgroundColor: "#fff",
                      color: "#000",
                    },
                    width: 250,
                  }}
                  size={isMobile ? "small" : "medium"}
                  variant="contained"
                  endIcon={<Download />}
                  onClick={() => handleDownload(verificationStatementFile)}
                >
                  Verification Statement
                </Button>
              )}
            </Stack>
          </Grid>
        )}

        {/*client Data*/}
        <ClientDetail client={client} />
      </Container>
    </Grid>
  );
};

export default ReportDetail;
