import React from "react";
import Header from "./Header";
import { Grid } from "@mui/material";
import Footer from "./Footer";

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div style={{ minHeight: "100vh" }}>
        <Grid container justifyContent="center">
          {children}
        </Grid>
      </div>
      <Footer />
    </>
  );
};

export default Layout;
