const sdgsBaseUrl = "https://www.un.org/sustainabledevelopment";

export const sdgData = [
  {
    id: "1",
    name: "No poverty",
    image: "/sdg-icons/1.png",
    link: `${sdgsBaseUrl}/poverty/#tab-aeeaf2d092abd802960`,
    bgColor: "#e5243b",
  },
  {
    id: "2",
    name: "Zero hunger",
    image: "/sdg-icons/2.png",
    link: `${sdgsBaseUrl}/hunger/#tab-6f500e1aa274b23d96d`,
    bgColor: "#dda83a",
  },
  {
    id: "3",
    name: "Good health and well-being",
    image: "/sdg-icons/3.png",
    link: `${sdgsBaseUrl}/health/#tab-3f22056b0e91266e8b2`,
    bgColor: "#4c9f38",
  },
  {
    id: "4",
    name: "Quality education",
    image: "/sdg-icons/4.png",
    link: `${sdgsBaseUrl}/education/#tab-bec3d6b2e412d024e05`,
    bgColor: "#c5192d",
  },
  {
    id: "5",
    name: "Gender equality",
    image: "/sdg-icons/5.png",
    link: `${sdgsBaseUrl}/gender-equality/#tab-b6328c70eedb23d97b1`,
    bgColor: "#ff3a21",
  },
  {
    id: "6",
    name: "Clean water and sanitation",
    image: "/sdg-icons/6.png",
    link: `${sdgsBaseUrl}/water-and-sanitation/#tab-a92f0eb1628b36792d5`,
    bgColor: "#26bde2",
  },
  {
    id: "7",
    name: "Affordable and clean energy",
    image: "/sdg-icons/7.png",
    link: `${sdgsBaseUrl}/energy/#tab-9ff9f7a21a3cd985360`,
    bgColor: "#fcc30b",
  },
  {
    id: "8",
    name: "Decent work and economic growth",
    image: "/sdg-icons/8.png",
    link: `${sdgsBaseUrl}/economic-growth/#tab-2f1cc1f12f763777498`,
    bgColor: "#a21942",
  },
  {
    id: "9",
    name: "Industry, innovation and infrastructure",
    image: "/sdg-icons/9.png",
    link: `${sdgsBaseUrl}/infrastructure-industrialization/#tab-f4621629c483c200ec0`,
    bgColor: "#fd6925",
  },
  {
    id: "10",
    name: "Reduced inequalities",
    image: "/sdg-icons/10.png",
    link: `${sdgsBaseUrl}/inequality/#tab-cb85489469cb0d48293`,
    bgColor: "#dd1367",
  },
  {
    id: "11",
    name: "Sustainable cities and communities",
    image: "/sdg-icons/11.png",
    link: `${sdgsBaseUrl}/cities/#tab-2267862e52845f3d178`,
    bgColor: "#fd9d24",
  },
  {
    id: "12",
    name: "Responsible consumption and production",
    image: "/sdg-icons/12.png",
    link: `${sdgsBaseUrl}/sustainable-consumption-production/#tab-d06cb4fe76db458f248`,
    bgColor: "#bf8b2e",
  },
  {
    id: "13",
    name: "Climate action",
    image: "/sdg-icons/13.png",
    link: `${sdgsBaseUrl}/climate-change/#tab-c4bdf348fc128b87c4d`,
    bgColor: "#3f7e44",
  },
  {
    id: "14",
    name: "Life below water",
    image: "/sdg-icons/14.png",
    link: `${sdgsBaseUrl}/oceans/#tab-260cea69595a56b794f`,
    bgColor: "#0a97d9",
  },
  {
    id: "15",
    name: "Life on land",
    image: "/sdg-icons/15.png",
    link: `${sdgsBaseUrl}/biodiversity/#tab-ca4ea19654992ca4ecb`,
    bgColor: "#56c02b",
  },
  {
    id: "16",
    name: "Peace, justice and strong institutions",
    image: "/sdg-icons/16.png",
    link: `${sdgsBaseUrl}/peace-justice/#tab-eb030c43e0900c3690b`,
    bgColor: "#00689d",
  },
  {
    id: "17",
    name: "Partnerships for the goals",
    image: "/sdg-icons/17.png",
    link: `${sdgsBaseUrl}/globalpartnerships/#tab-d100a91968edd4cd742`,
    bgColor: "#19486a",
  },
];
