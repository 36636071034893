import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import {
  getOrganizationByRegisterNumber,
  getOrganizationMaterialTopic,
} from "../../services/OrganizationsServices";
import moment from "moment";
import { Download, NavigateNext } from "@mui/icons-material";
import * as Constants from "../../support/Constants";
import { getMaterialTopicInstrument } from "../../services/MaterialTopicServices";
import { getFiles } from "../../services/FilesServices";

import Loading from "../Loading";
import NotFound from "../NotFound";

const InstrumentData = () => {
  const { registerNumber, materialTopic, instrument } = useParams();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const organizationsQuery = useQuery(["organization", registerNumber], () => {
    return getOrganizationByRegisterNumber(registerNumber);
  });

  const {
    data: organization,
    isLoading: loadingOrganization,
    isError: organizationError,
  } = organizationsQuery;

  const materialTopicQuery = useQuery(
    ["materialTopic", registerNumber, materialTopic],
    () => {
      return getOrganizationMaterialTopic(registerNumber, materialTopic);
    }
  );

  const {
    data: materialTopicData,
    isLoading: loadingMaterialTopic,
    isError: materialTopicsError,
  } = materialTopicQuery;

  const instrumentQuery = useQuery(
    ["instrumentQuery", materialTopic, instrument],
    () => {
      return getMaterialTopicInstrument(materialTopic, instrument);
    }
  );

  const {
    data: instrumentData,
    isLoading: loadingInstrument,
    isError: instrumentError,
  } = instrumentQuery;

  const filesQuery = useQuery(["filesQuery", instrumentData], () => {
    return getFiles(instrumentData?.files);
  });

  const {
    data: files,
    isLoading: loadingFiles,
    isError: filesError,
  } = filesQuery;

  if (loadingMaterialTopic || loadingInstrument || loadingFiles) {
    return <Loading />;
  }

  if (!loadingInstrument && !instrumentData) {
    return <NotFound />;
  }

  const handleDownload = (file) => {
    fetch(file.link)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file.name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      });
  };

  return (
    <Grid container mt={5} mb={8}>
      <Grid item display="flex" xs={12} sx={{ marginBottom: 5, marginX: 5 }}>
        <Breadcrumbs separator={<NavigateNext />} aria-label="breadcrumb">
          <Link underline="hover" className="home-link" to="/">
            Home
          </Link>
          <Link underline="hover" className="home-link" to="/organizations">
            Organizations
          </Link>
          <Link className="home-link" to={`/organizations/${registerNumber}`}>
            {organization?.clientName}
          </Link>
          <Link
            className="home-link"
            to={`/organizations/${registerNumber}/materialTopics`}
          >
            Material Topics
          </Link>
          <Link
            className="home-link"
            to={`/organizations/${registerNumber}/materialTopics/${materialTopic}`}
          >
            {materialTopicData?.name}
          </Link>
          <Link
            className="home-link"
            to={`/organizations/${registerNumber}/materialTopics/${materialTopic}/instruments`}
          >
            Instruments
          </Link>
          <Typography color="textPrimary">{instrumentData?.name}</Typography>
        </Breadcrumbs>
      </Grid>

      <Container>
        {instrumentData && (
          <Grid
            container
            direction="row"
            display="flex"
            justifyContent="center"
            rowGap={1.5}
          >
            <Grid item justifyContent="center">
              <Typography
                variant="h4"
                align="center"
                sx={{ marginBottom: 8, fontFamily: "Inter-Medium" }}
              >
                {instrumentData?.name}
              </Typography>
            </Grid>
            <Grid
              container
              padding={2}
              sx={{
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" align="left">
                  Version
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" align="left">
                  {instrumentData?.version}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              padding={2}
              sx={{
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" align="left">
                  Issued by
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" align="right">
                  {instrumentData?.issuer}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              padding={2}
              sx={{
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" align="left">
                  Evaluation Type
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" align="left">
                  {Constants.getInstrumentType(instrumentData?.evaluationType)}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              padding={2}
              sx={{
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" align="left">
                  Owner
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" align="left">
                  {instrumentData?.owner}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              padding={2}
              sx={{
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" align="left">
                  Scope
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" align="left">
                  {instrumentData?.scope}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              padding={2}
              sx={{
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" align="left">
                  Valid until
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" align="left">
                  {moment
                    .unix(instrumentData?.validityDate)
                    .format("DD-MM-YYYY")}
                </Typography>
              </Grid>
            </Grid>
            {instrumentData?.addOnAltus !== "" && (
              <Grid
                container
                padding={2}
                sx={{
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="h6" align="left">
                    Add on Altus
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="h6" align="left">
                    {instrumentData?.addOnAltus}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              padding={2}
              sx={{
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
              }}
            >
              <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" align="left">
                  Files
                </Typography>
              </Grid>
              <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="h6" align="left">
                  <Stack>
                    {files?.length > 0 ? (
                      files?.map((file, index) => {
                        const truncatedName =
                          file.name.length > 10
                            ? file.name.substring(0, 10) +
                              "..." +
                              file.extension
                            : file.name;

                        return (
                          <Box key={index} sx={{ marginBottom: "8px" }}>
                            <Tooltip
                              title={<Typography>{file.name}</Typography>}
                              placement="top"
                              arrow
                            >
                              <Button
                                sx={{
                                  "&:hover": {
                                    backgroundColor: "#fff",
                                    color: "#000",
                                  },
                                }}
                                size={isMobile ? "small" : "medium"}
                                variant="contained"
                                endIcon={<Download />}
                                onClick={() => handleDownload(file)}
                              >
                                {truncatedName}
                              </Button>
                            </Tooltip>
                          </Box>
                        );
                      })
                    ) : (
                      <Typography variant="h6" align="left">
                        No attached files{" "}
                      </Typography>
                    )}
                  </Stack>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Container>
    </Grid>
  );
};

export default InstrumentData;
