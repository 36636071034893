export function convertGoogleDriveLink(link) {
  // Extract the file ID from the provided URL
  const fileId = getFileIdFromGoogleDriveLink(link);

  const parsedLink = `https://drive.google.com/thumbnail?id=${fileId}&sz=w2000`;

  return parsedLink;
}

function getFileIdFromGoogleDriveLink(link) {
  // Extract the file ID from the URL
  const match = link.match(/\/d\/([a-zA-Z0-9_-]+)\/view/);

  // Return the ID if found, otherwise return null
  return match ? match[1] : null;
}

export function isGoogleDriveLink(link) {
  // Check if the link contains the expected pattern for a Google Drive link
  return (
    /\/file\/d\//.test(link) ||
    /\/open\?id=/.test(link) ||
    /\/uc\?id=/.test(link) ||
    /\/d\//.test(link)
  );
}
