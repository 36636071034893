// Status de un miembro
export const USER_STATUS_ANY = 0; // Para las busquedas
export const USER_STATUS_ENABLED = 1;
export const USER_STATUS_DISABLE = 2;
//Dejo los anteriores para no romper todo, pero pronongo solo Enable/Disable para esta fase
export const USER_STATUS_PENDING = 10;
export const USER_STATUS_APPROVED = 20;
export const USER_STATUS_REJECTED = 30;
export const USER_STATUS_DISABLED = 40;
export const USER_STATUS_REMOVED = 50; // Para la baja logica

// Status de un cliente
export const CLIENT_STATUS_ANY = 0;
export const CLIENT_STATUS_ENABLED = 1;
export const CLIENT_STATUS_DISABLED = 2;

// Status de una organizacion
export const ORGANIZATION_STATUS_ANY = 0;
export const ORGANIZATION_STATUS_VIGENTE = 1;
export const ORGANIZATION_STATUS_VENCIDO = 2;
export const ORGANIZATION_STATUS_RETIRADO = 3;
export const ORGANIZATION_STATUS_SUSPENDIDO = 4;
export const ORGANIZATION_STATUS_CANCELADO = 5;
export const ORGANIZATION_STATUS_ANULADO = 6;

// Nivel registro organización
export const ORGANIZATION_LEVEL_NO_LEVEL = 0;
export const ORGANIZATION_LEVEL_REGISTER = 1;
export const ORGANIZATION_LEVEL_COMMITMENT = 2;

// Responsabilidad evaluación
export const EVALUATION_RESPONSIBILITY_NO_RESPONSIBLE = 0;
export const EVALUATION_RESPONSIBILITY_EVALUATED = 1;
export const EVALUATION_RESPONSIBILITY_VALIDATED = 2;

// Codigos de rol
export const ROLE_NOROLE = 0; // Indicador de que no hay rol
export const ROLE_ADMIN = 1;
export const ROLE_GESTION = 2;

// Status de un tema material

export const MATERIAL_TOPIC_STATUS_ANY = 0;
export const MATERIAL_TOPIC_STATUS_MANAGED = 1;
export const MATERIAL_TOPIC_STATUS_IN_PROGRESS = 2;

// Instrumentos

export const INSTRUMENT_ISSUER_ALTUS = 1;
export const INSTRUMENT_ISSUER_OTROS = 2;

export const INSTRUMENT_TYPE_CERTIFICACION = 1;
export const INSTRUMENT_TYPE_EVALUACION = 2;
export const INSTRUMENT_TYPE_VERIFICACION = 3;
export const INSTRUMENT_TYPE_INSPECCION = 4;
export const INSTRUMENT_TYPE_OTRO = 5;

// Public Commitments

export const PUBLIC_COMMITMENTS_VERIFICATION_STATUS_IN_ANALYSIS = 1;
export const PUBLIC_COMMITMENTS_VERIFICATION_STATUS_ACHIEVED = 2;
export const PUBLIC_COMMITMENTS_VERIFICATION_STATUS_NOT_ACHIEVED = 3;

// Antecedents
export const ANTECEDENT_VERIFICATION_LEVEL_VERIFIED = 1;
export const ANTECEDENT_VERIFICATION_LEVEL_IN_PROGRESS = 2;

// Reports

export const REPORTS_STANDARD_ANY = 0;
export const REPORTS_STANDARD_GRI = 1;
export const REPORTS_STANDARD_COPGLOBAL = 2;
export const REPORTS_STANDARD_ESRS = 3;
export const REPORTS_STANDARD_BIA = 4;
export const REPORTS_STANDARD_OTHER = 5;

// Nivel registro practica
export const PRACTICE_LEVEL_NO_LEVEL = 0;
export const PRACTICE_LEVEL_COMMITMENT = 1;
export const PRACTICE_LEVEL_COMPLIANCE = 2;
export const PRACTICE_LEVEL_ADVANCECOMPLIANCE = 3;

export const PRACTICE_STATUS_ANY = 0;
export const PRACTICE_STATUS_VIGENTE = 1;
export const PRACTICE_STATUS_VENCIDO = 2;
export const PRACTICE_STATUS_RETIRADO = 3;
export const PRACTICE_STATUS_SUSPENDIDO = 4;
export const PRACTICE_STATUS_CANCELADO = 5;
export const PRACTICE_STATUS_ANULADO = 6;
export const PRACTICE_STATUS_COMPLETADO = 7;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Funciones de ayuda generales

export function getRoleName(rolCode) {
  if (rolCode === ROLE_ADMIN) return "Admin";
  if (rolCode === ROLE_GESTION) return "Gestión";
  return "";
}

export function getStatusName(value) {
  if (value === USER_STATUS_ENABLED) {
    return "Habilitado";
  } else if (value === USER_STATUS_DISABLE) {
    return "Deshabilitado";
  } else if (value === USER_STATUS_PENDING) {
    return "Pendiente";
  } else if (value === USER_STATUS_APPROVED) {
    return "Aprobado";
  } else if (value === USER_STATUS_REJECTED) {
    return "Rechazado";
  } else if (value === USER_STATUS_DISABLED) {
    return "Suspendido";
  } else if (value === USER_STATUS_REMOVED) {
    return "Dado de baja";
  } else {
    return "Indeterminado";
  }
}

export function getOrganizationStatusName(value) {
  if (value === ORGANIZATION_STATUS_VIGENTE) {
    return "Valid";
  } else if (value === ORGANIZATION_STATUS_VENCIDO) {
    return "Expired";
  } else if (value === ORGANIZATION_STATUS_RETIRADO) {
    return "Withdrawn";
  } else if (value === ORGANIZATION_STATUS_SUSPENDIDO) {
    return "Suspended";
  } else if (value === ORGANIZATION_STATUS_CANCELADO) {
    return "Cancelled";
  } else if (value === ORGANIZATION_STATUS_ANULADO) {
    return "Voided";
  } else {
    return "Unknown";
  }
}

export function getRegisterLevelName(value) {
  if (value === ORGANIZATION_LEVEL_REGISTER) {
    return "Register";
  } else if (value === ORGANIZATION_LEVEL_COMMITMENT) {
    return "Commitment";
  } else {
    return "Unknown";
  }
}

export function formatRegisterNumber(registerNumber) {
  if (registerNumber < 10) {
    return `00${registerNumber}`;
  } else if (registerNumber >= 10 && registerNumber < 100) {
    return `0${registerNumber}`;
  } else {
    return registerNumber.toString();
  }
}

export function getEvaluationResponsibleName(value) {
  if (value === EVALUATION_RESPONSIBILITY_EVALUATED) {
    return "Evaluated by Altus";
  } else if (value === EVALUATION_RESPONSIBILITY_VALIDATED) {
    return "Validated by Altus";
  } else {
    return "No responsible";
  }
}

export function getMaterialTopicStatusName(value) {
  if (value === MATERIAL_TOPIC_STATUS_MANAGED) {
    return "Managed";
  } else if (value === MATERIAL_TOPIC_STATUS_IN_PROGRESS) {
    return "In progress";
  } else {
    return "Unknown";
  }
}

export function getInstrumentIssuer(value) {
  if (value === INSTRUMENT_ISSUER_ALTUS) {
    return "Altus";
  } else if (value === INSTRUMENT_ISSUER_OTROS) {
    return "Others";
  } else {
    return "Unknown";
  }
}

export function getInstrumentType(value) {
  if (value === INSTRUMENT_TYPE_CERTIFICACION) {
    return "Certification";
  } else if (value === INSTRUMENT_TYPE_EVALUACION) {
    return "Evaluation";
  } else if (value === INSTRUMENT_TYPE_INSPECCION) {
    return "Inspection";
  } else if (value === INSTRUMENT_TYPE_VERIFICACION) {
    return "Verification";
  } else if (value === INSTRUMENT_TYPE_OTRO) {
    return "Other";
  } else {
    return "Unknown";
  }
}

export function getPublicCommitmentStatus(value) {
  if (value === PUBLIC_COMMITMENTS_VERIFICATION_STATUS_IN_ANALYSIS) {
    return "In analysis";
  } else if (value === PUBLIC_COMMITMENTS_VERIFICATION_STATUS_ACHIEVED) {
    return "Achieved";
  } else if (value === PUBLIC_COMMITMENTS_VERIFICATION_STATUS_NOT_ACHIEVED) {
    return "Not achieved";
  } else {
    return "Unkwown";
  }
}

export function getAntecedentVerificationStatusName(value) {
  if (value === ANTECEDENT_VERIFICATION_LEVEL_VERIFIED) {
    return "Verified";
  } else if (value === ANTECEDENT_VERIFICATION_LEVEL_IN_PROGRESS) {
    return "In progress";
  } else {
    return "Indeterminado";
  }
}

export function getReportStandardName(value) {
  if (value === REPORTS_STANDARD_BIA) {
    return "BIA";
  } else if (value === REPORTS_STANDARD_COPGLOBAL) {
    return "CO Pacto Global";
  } else if (value === REPORTS_STANDARD_ESRS) {
    return "ESRS";
  } else if (value === REPORTS_STANDARD_GRI) {
    return "GRI";
  } else if (value === REPORTS_STANDARD_OTHER) {
    return "Otro";
  } else {
    return "Indeterminado";
  }
}

export function getPracticeLevelName(value) {
  if (value === PRACTICE_LEVEL_COMMITMENT) {
    return "Commitment";
  } else if (value === PRACTICE_LEVEL_COMPLIANCE) {
    return "Compliance";
  } else if (value === PRACTICE_LEVEL_ADVANCECOMPLIANCE) {
    return "Advanced compliance";
  } else {
    return "Indeterminado";
  }
}

export function getPracticeStatusName(value) {
  if (value === PRACTICE_STATUS_VIGENTE) {
    return "Valid";
  } else if (value === PRACTICE_STATUS_VENCIDO) {
    return "Expired";
  } else if (value === PRACTICE_STATUS_RETIRADO) {
    return "Withdrawn";
  } else if (value === PRACTICE_STATUS_SUSPENDIDO) {
    return "Suspended";
  } else if (value === PRACTICE_STATUS_CANCELADO) {
    return "Cancelled";
  } else if (value === PRACTICE_STATUS_ANULADO) {
    return "Voided";
  } else if (value === PRACTICE_STATUS_COMPLETADO) {
    return "Completed";
  } else {
    return "Unkwnown";
  }
}

export function convertToEmbedUrl(url) {
  const youtubeRegex =
    /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?(?:.*&)?v=|embed\/|v\/|u\/\w\/|embed\/?)|youtu\.be\/)([^#\&\?]*).*/;
  const match = url.match(youtubeRegex);

  if (match && match[1]) {
    const videoId = match[1];
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    return embedUrl;
  }

  return null;
}
