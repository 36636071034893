import axios from "axios";
import { url_base_api } from "../utils/config";

export const getFiles = async (codes) => {
  const fileCodes = codes?.map((code) => parseInt(code?.hex));
  if (fileCodes?.length === 0) return;
  let files = [];
  try {
    const response = await axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${url_base_api}/files/${fileCodes}`,
    });

    if (response.data.files.length > 0) {
      response.data.files.forEach((file) => {
        files.push({
          code: file.code,
          name: file.name,
          extension: file.extension,
          hashCode: file.hashCode,
          link: `https://ipfs.io/ipfs/${file.hashCode}?fileName=${file.name}`,
        });
      });
    }
    return files;
  } catch (error) {
    console.log(error);
  }
};
