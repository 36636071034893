import React from "react";
import { Grid, Tooltip, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { getOrganizationCoveredSDGs } from "../../services/OrganizationsServices";
import { Link } from "react-router-dom";

import { sdgData } from "../../utils/sustainableDevelopmetGoalsData";

const CoveredSDGs = ({ registerNumber }) => {
  const coveredSDGsQuery = useQuery(["coveredSDGs", registerNumber], () => {
    return getOrganizationCoveredSDGs(registerNumber);
  });

  const {
    data: coveredSDGsData,
    isLoading: loadingCoveredSDGs,
    isError: coveredSDGserror,
  } = coveredSDGsQuery;

  const allSDGs = coveredSDGsData?.odsManaged?.concat(
    coveredSDGsData?.odsInProcess
  );

  const managedSDGsIds = coveredSDGsData?.odsManaged.map(
    (item) => item?.split(".")[0]
  );

  const allSDGsUnique = [
    ...new Set(allSDGs?.map((item) => item?.split(".")[0])),
  ];

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      sx={{ marginTop: 10, marginBottom: 3 }}
    >
      {allSDGsUnique
        .sort((a, b) => a - b)
        .map((sdg) => {
          const sdgInfo = sdgData.find((item) => item.id === sdg);
          if (sdgInfo) {
            return (
              <Grid key={sdg} item className="sdg-flip-card">
                <Tooltip
                  title={
                    !managedSDGsIds.includes(sdg) ? (
                      <Typography>In process</Typography>
                    ) : (
                      <Typography>Managed</Typography>
                    )
                  }
                  placement="top"
                  arrow
                >
                  <Grid className="sdg-flip-card-inner">
                    <Link
                      to={`/organizations/${registerNumber}/sustainabilityIndicators/${sdgInfo.id}`}
                    >
                      <Grid className="sdg-flip-card-front">
                        <img
                          key={sdg}
                          src={sdgInfo.image}
                          alt={`SDG ${sdg}`}
                          style={{
                            cursor: "pointer",
                            filter: !managedSDGsIds.includes(sdg)
                              ? "grayscale(100%)"
                              : "none",
                            opacity: !managedSDGsIds.includes(sdg) ? 0.3 : 1,
                          }}
                        />
                      </Grid>
                    </Link>
                  </Grid>
                </Tooltip>
              </Grid>
            );
          } else {
            return null;
          }
        })}
    </Grid>
  );
};

export default CoveredSDGs;
