import { CircularProgress, Grid } from "@mui/material";
import React from "react";

const Loading = () => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      display="flex"
      sx={{ height: "100vh", opacity: 0.3 }}
    >
      <Grid>
        <CircularProgress size={100} />
      </Grid>
    </Grid>
  );
};

export default Loading;
