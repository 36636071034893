import { Routes, Route, BrowserRouter } from "react-router-dom";

import CoverageIndexDetail from "../view/organizations/CoverageIndexDetail";
import InstrumentData from "../view/organizations/InstrumentData";
import MaterialTopicInstruments from "../view/organizations/MaterialTopicInstruments";
import MaterialTopicPublicCommitmentData from "../view/organizations/MaterialTopicPublicCommitmentData";
import MaterialTopicPublicCommitments from "../view/organizations/MaterialTopicPublicCommitments";
import OrganizationDetailPage from "../view/organizations/OrganizationDetailPage";
import OrganizationMaterialTopicDetail from "../view/organizations/OrganizationMaterialTopicDetail";
import OrganizationMaterialTopics from "../view/organizations/OrganizationMaterialTopics";
import OrganizationRegisterData from "../view/organizations/OrganizationRegisterData";
import OrganizationsPage from "../view/organizations/OrganizationsPage";
import SustainabilityIndicators from "../view/organizations/SustainabilityIndicators";
import TrustIndexDetail from "../view/organizations/TrustIndexDetail";
import OrganizationPublicCommitments from "../view/organizations/OrganizationPublicCommitments";
import PublicCommitmentDetail from "../view/organizations/PublicCommitmentDetail";

import Layout from "../components/layout/Layout";

import HomePage from "../view/HomePage";

import NotFound from "../view/NotFound";

import ServiceDetail from "../view/services/ServiceDetail";
import ServicesPage from "../view/services/ServicesPage";

import ServiceBackground from "../view/services/ServiceBackground";
import BackgroundDetail from "../view/services/BackgroundDetail";
import PracticesPage from "../view/practices/PracticesPage";
import PracticeDetail from "../view/practices/PracticeDetail";
import ReportsPage from "../view/reports/ReportsPage";
import ReportDetail from "../view/reports/ReportDetail";
import ContractsInfo from "../view/ContractsInfo";
import SDGDetail from "../view/organizations/SDGDetail";
import ScrollToTop from "../components/layout/ScrollToTop";

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route exact path="/" element={<HomePage />} />

          {/*organizations*/}
          <Route exact path="/organizations" element={<OrganizationsPage />} />
          <Route
            exact
            path="/organizations/:registerNumber"
            element={<OrganizationDetailPage />}
          />
          <Route
            exact
            path="/organizations/:registerNumber/registerData"
            element={<OrganizationRegisterData />}
          />
          <Route
            exact
            path="/organizations/:registerNumber/materialTopics"
            element={<OrganizationMaterialTopics />}
          />
          <Route
            exact
            path="/organizations/:registerNumber/materialTopics/:materialTopic"
            element={<OrganizationMaterialTopicDetail />}
          />
          <Route
            exact
            path="/organizations/:registerNumber/materialTopics/:materialTopic/instruments"
            element={<MaterialTopicInstruments />}
          />
          <Route
            exact
            path="/organizations/:registerNumber/materialTopics/:materialTopic/instruments/:instrument"
            element={<InstrumentData />}
          />

          <Route
            exact
            path="/organizations/:registerNumber/materialTopics/:materialTopic/publicCommitments"
            element={<MaterialTopicPublicCommitments />}
          />

          <Route
            exact
            path="/organizations/:registerNumber/materialTopics/:materialTopic/publicCommitments/:publicCommitment"
            element={<MaterialTopicPublicCommitmentData />}
          />

          <Route
            exact
            path="/organizations/:registerNumber/sustainabilityIndicators"
            element={<SustainabilityIndicators />}
          />

          <Route
            exact
            path="/organizations/:registerNumber/sustainabilityIndicators/trustIndex"
            element={<TrustIndexDetail />}
          />

          <Route
            exact
            path="/organizations/:registerNumber/sustainabilityIndicators/coverageIndex"
            element={<CoverageIndexDetail />}
          />

          <Route
            exact
            path="/organizations/:registerNumber/publicCommitments"
            element={<OrganizationPublicCommitments />}
          />

          <Route
            exact
            path="/organizations/:registerNumber/publicCommitments/:materialTopic"
            element={<PublicCommitmentDetail />}
          />

          <Route
            exact
            path="/organizations/:registerNumber/sustainabilityIndicators/:sdgNumber"
            element={<SDGDetail />}
          />

          {/*services*/}
          <Route exact path="/services" element={<ServicesPage />} />

          <Route exact path="/services/:service" element={<ServiceDetail />} />
          <Route
            exact
            path="/services/:service/background"
            element={<ServiceBackground />}
          />

          <Route
            exact
            path="/services/:service/background/:backgroundCode"
            element={<BackgroundDetail />}
          />

          {/*practices*/}
          <Route exact path="/practices" element={<PracticesPage />} />

          <Route
            exact
            path="/practices/:practice"
            element={<PracticeDetail />}
          />

          {/*reports*/}

          <Route exact path="/reports" element={<ReportsPage />} />

          <Route exact path="/reports/:report" element={<ReportDetail />} />

          {/*blockchain info*/}

          <Route exact path="/contracts-info" element={<ContractsInfo />} />

          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};
