import { NavigateNext } from "@mui/icons-material";
import { Grid, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

const ServiceBackgroundLink = ({ service }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <Grid container spacing={1.5} justifyContent="center" mt={5}>
      <Grid item xs={isMobile ? 12 : 6}>
        <Grid
          container
          className="navigation-grid"
          onClick={() => navigate(`/services/${service}/background`)}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography
              className="navigation-grid-title"
              sx={{ overflowWrap: "break-word", overflow: "hidden" }}
            >
              BACKGROUND
            </Typography>
            <NavigateNext sx={{ justifyContent: "flex-end" }} />
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ServiceBackgroundLink;
